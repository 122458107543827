const VerticalTabItem = ({ id, label, active, onClick, disabled }) => {
  return (
    <>
      <button
        id={`${id}-tab`}
        className={`block pr-2 pl-2 text-sm  text-gray-600  transition-all duration-800  hover:scale-105 cursor-pointer  hover:text-primary ${
          active
            ? 'font-bold focus:font-bold border-primary text-primary focus:text-primary border-r-4 mr-1 '
            : ' font-light '
        } ${disabled ? 'cursor-not-allowed' : ''}`}
        onClick={onClick}
        disabled={disabled}
        aria-controls={id}
        role="tab"
        // aria-selected={active}
      >
        {label}
      </button>
      <hr />
    </>
  );
};

export default VerticalTabItem;
