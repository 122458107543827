import { Link } from 'react-router-dom';

function CareerCard({ to = '', title = 'Vacancy Title', sn }) {
  return (
    <div className="w-full h-32">
      <Link to={to}>
        <div className="ml-8 mr-8 bg-white border border-gray-200 rounded-lg shadow  transition duration-300 hover:scale-103">
          <div className="p-5 flex justify-between">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              {sn}. {title}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"></p>
            <p className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Read more
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default CareerCard;
