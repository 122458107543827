import React, { useEffect } from 'react';

const ScrollToElement = ({ elementId, children }) => {
  useEffect(() => {
    // if (elementId !== 'tabs-menu') {
    //   const element = document.getElementById(elementId);
    //   if (element) {
    //     const topOffset = element.offsetTop - 110;
    //     window.scrollTo({ top: topOffset, behavior: 'smooth' });
    //   }
    // }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [elementId]);

  return (
    <div className="container responsive-width mx-auto">
      <div
        className={`pt-12 pb-12 pl-1 pr-1 margin-auto top-[50px] sticky duration-150 ease-linear`}
        role="tabpanel"
        aria-labelledby="tabs-menu-tab"
        id={elementId}
        style={{ alignItems: 'center' }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScrollToElement;
