import { useState } from 'react';
import { useForm } from 'react-hook-form';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import { postResetPassword } from '../../../../services/RequestHandler/AuthRequest';
import SubmitSpinner from '../../../components/common/SubmitSpinner';

const NewPassword = ({ globalEmail }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };
  const handleToggleConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const watchPassword = watch('password');

  const onSubmit = async payload => {
    setIsSubmitting(true);
    try {
      const response = await postResetPassword({
        ...payload,
        email: globalEmail,
      });
      if (response) {
        navigate('/login');
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen sm:bg-gray-50">
      <div className="bg-white px-6 pt-10 pb-9 sm:shadow-xl mx-auto sm:rounded-2xl min-w-[25rem] ">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">
              <p>Reset Password</p>
            </div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>Please enter new password for your account below</p>
            </div>
          </div>

          <div>
            <form
              className="mt-4 space-y-4 md:space-y-6 "
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-4 justify-between">
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Password <span className="text-red-600">*</span>
                  </label>
                  <div className="relative flex w-full h-full">
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      id="password"
                      name="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      placeholder="Enter your Password"
                      {...register('password', {
                        required: 'Password is required',
                        maxLength: {
                          value: 20,
                          message: 'Maximum password length is 20',
                        },
                        minLength: {
                          value: 8,
                          message: 'Minimum password length is 8',
                        },
                      })}
                    />

                    <div className="absolute right-0 flex justify-center items-center h-full mr-3 cursor-pointer">
                      {passwordShown ? (
                        <VisibilityOutlinedIcon onClick={handleToggle} />
                      ) : (
                        <VisibilityOffOutlinedIcon onClick={handleToggle} />
                      )}
                    </div>
                  </div>
                  {errors.password && (
                    <p className="text-red-600">{errors.password.message}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password_confirmation"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Confirm Password <span className="text-red-600">*</span>
                  </label>
                  <div className="relative flex w-full h-full">
                    <input
                      type={passwordShownConfirm ? 'text' : 'password'}
                      id="password_confirmation"
                      name="password_confirmation"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      placeholder="Enter Confirmation Password"
                      {...register('password_confirmation', {
                        required: 'Confirm password is required',
                        validate: value =>
                          value === watchPassword ||
                          'The passwords do not match',
                      })}
                    />

                    <div className="absolute right-0 flex justify-center items-center h-full mr-3 cursor-pointer">
                      {passwordShownConfirm ? (
                        <VisibilityOutlinedIcon onClick={handleToggleConfirm} />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          onClick={handleToggleConfirm}
                        />
                      )}
                    </div>
                  </div>
                  {errors.password_confirmation && (
                    <p className="text-red-600">
                      {errors.password_confirmation.message}
                    </p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`flex flex-row ${
                  isSubmitting ? 'cursor-normal' : 'cursor-pointer'
                } items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm ${
                  isSubmitting ? 'bg-gray-400' : 'bg-primary'
                }`}
              >
                {isSubmitting ? <SubmitSpinner /> : null}
                <span className="py-2">Confirm</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewPassword;
