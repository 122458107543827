import { useForm, FormProvider } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DeliveryDetail from '../../components/Checkout/DeliveryDetail';
import DeliveryDetailOfGift from '../../components/Checkout/DeliveryDetailOfGift';
import { useState } from 'react';
import CartDetail from '../../components/Checkout/CartDetail';
import { postCheckout } from '../../../services/RequestHandler/ApiPostRequest';
import Modal from '../../components/common/Modal';
import ConfirmOrderModal from '../../components/Modal/ConfirmOrder';
import OrderPlacedModal from '../../components/Modal/OrderPlacedModal';
import SubmitSpinner from '../../components/common/SubmitSpinner';
import { checkOpeningHours } from '../../../helper/dateTime';

function CheckoutPage() {
  const dispatch = useDispatch();
  const [isGiftForFriend, setGiftForFriend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setIsGift = () => setGiftForFriend(true);
  const removeIsGift = () => setGiftForFriend(false);
  const formMethods = useForm();
  const { items } = useSelector(state => state.cart);
  const [orderPlacedModalOpen, setOrderPlacedModalOpen] = useState(false);
  const [billingAddress, setBillingAddress] = useState({});
  const hideOrderPlacedModal = () => setOrderPlacedModalOpen(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const hideConfirmModal = () => setConfirmModalOpen(false);

  const openConfirmModal = async () => {
    try {
      const isCafeOpen = checkOpeningHours('9:00', '21:00');
      if (!isCafeOpen) {
        return toast.error('We are closed today, Please order tomorrow!!');
      }
      await formMethods.trigger(); // Manually trigger form validation
      if (formMethods.formState.isValid) setConfirmModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async payload => {
    try {
      setIsSubmitting(true);
      if (items.length <= 0)
        return toast.error('Please select at least one items');
      const isTrue = await postCheckout(dispatch, {
        ...payload,
        checkoutCart: [...items],
      });
      if (isTrue) {
        formMethods.reset();
        //navigate to order checkout-success code page
        setBillingAddress({
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
          address: payload.address,
        });
        if (payload?.friend_name) {
          setBillingAddress({
            fullName: payload.friend_name,
            phoneNumber: payload.friend_number,
            address: payload.friend_address,
          });
        } else {
          setBillingAddress({
            fullName: payload.fullName,
            phoneNumber: payload.phoneNumber,
            address: payload.address,
          });
        }
        setOrderPlacedModalOpen(true);
      }
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitForm = () => {
    setConfirmModalOpen(false);
    formMethods.handleSubmit(onSubmit)();
  };
  return (
    <div className="w-full font-roboto">
      <Helmet>
        <title>Checkout</title>
        <meta name="description" content="Profile page" />
      </Helmet>
      <div className="bg-gray-100 py-4 border-b border-gray-300 shadow">
        <div className="responsive-width px-2 flex justify-between items-center pt-8">
          <h1 className="text-4xl tracking-widest text-gray-700">Checkout</h1>
        </div>
      </div>

      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="responsive-width grid grid-cols-12 gap-6 py-8"
        >
          <div className="col-span-12 lg:col-span-7">
            {isGiftForFriend ? <DeliveryDetailOfGift /> : <DeliveryDetail />}
          </div>
          <div className="col-span-12 lg:col-span-5">
            <div className="flex justify-end">
              <button
                onClick={isGiftForFriend ? removeIsGift : setIsGift}
                className="btn-primary py-1 block w-2/3"
                type="button"
              >
                {isGiftForFriend ? 'Back to normal' : 'GIFT YOUR FRIEND 🎁'}
              </button>
            </div>
            <div className="border p-4 my-4">
              <h1 className="text-2xl">Cart</h1>
              <hr />
              {items.length > 0 ? (
                <div>
                  <CartDetail />
                  <div className="my-2">
                    <button
                      onClick={openConfirmModal}
                      className={`btn-primary block w-3/4 mx-auto px-2 py-1 ${
                        isSubmitting
                          ? 'hover:bg-gray-300 bg-gray-300 disabled  border-gray-300'
                          : ''
                      } `}
                      type="button"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && <SubmitSpinner />}
                      Confirm Order
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-3 flex flex-col gap-2">
                  <p className="text-red-400">Your cart is empty !!</p>
                  <p>Add item to place order!!</p>
                </div>
              )}
            </div>
          </div>
          <Modal isOpen={confirmModalOpen} onRequestClose={hideConfirmModal}>
            <ConfirmOrderModal
              closeModal={hideConfirmModal}
              submitForm={handleSubmitForm}
            />
          </Modal>
        </form>
      </FormProvider>
      <hr className="mb-6 responsive-width" />
      <div className="my-4 responsive-width">
        <h1 className="text-2xl">Delivery Charges</h1>
        <div className="flex flex-col gap-3 text-sm opacity-70 mt-6">
          <p>Based on distance from our restaurant</p>
          <div className="flex justify-between">
            <p>Rs. 50 (1km)</p>
            <p>Rs. 100 (2-3km)</p>
            <p>Rs. 150 (3-5km)</p>
            <p>Rs. 200 (5+km)</p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={orderPlacedModalOpen}
        onRequestClose={hideOrderPlacedModal}
      >
        <OrderPlacedModal billingAddress={billingAddress} />
      </Modal>
    </div>
  );
}

export default CheckoutPage;
