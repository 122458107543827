import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TinyMceContent from '../../../components/common/TinyMceContent';

const About = () => {
  const { slug } = useParams();
  const { branches } = useSelector(state => state.productCategoriesPage);
  const [about, setAbout] = useState('');

  // useEffect(() => {
  //   const element = document.getElementById('tabs-about');
  //   if (element) {
  //     const topOffset = element.offsetTop - 110;
  //     window.scrollTo({ top: topOffset, behavior: 'smooth' });
  //   }
  // }, []);
  useEffect(() => {
    const existingBranch = branches.find(branch => branch.branch.slug === slug);
    setAbout(
      () =>
        existingBranch.branch.branch_details.find(
          detail => detail.config_id === 3,
        ).value,
    );
  }, [slug, branches]);

  return (
    <div
      className={`responsive-width pl-2 pr-2 top-[50px] sticky duration-150 ease-linear  `}
      role="tabpanel"
      aria-labelledby="tabs-menu-tab"
      id="tabs-about"
      style={{ alignItems: 'center' }}
    >
      <TinyMceContent content={about} />
    </div>
  );
};
export default About;
