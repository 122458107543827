export const timeAgo = date => {
  const now = new Date();
  const diffMilliseconds = now - new Date(date);
  const seconds = Math.floor(diffMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
  }
};

export const checkOpeningHours = (openHour, closeHour) => {
  // Get the current time
  var now = new Date();
  var currentHour = now.getHours();
  var currentMinutes = now.getMinutes();

  // Convert openHour and closeHour to minutes
  var openTime =
    parseInt(openHour.split(':')[0]) * 60 + parseInt(openHour.split(':')[1]);
  var closeTime =
    parseInt(closeHour.split(':')[0]) * 60 + parseInt(closeHour.split(':')[1]);

  // Convert current time to minutes
  var currentTime = currentHour * 60 + currentMinutes;
  console.log(openTime, currentTime, closeTime);
  // Check if current time is within the range
  if (currentTime >= openTime && currentTime <= closeTime) {
    return true;
  } else {
    return false;
  }
};
