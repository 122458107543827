import React from 'react';

function NewsLetter() {
  return (
    <div className="bg-[#F7F6F2] px-2 py-16 my-20">
      <div className="responsive-width flex flex-col justify-between md:items-center md:flex-row gap-4 large:gap-40">
        <div className="">
          <h1 className="tracking-wide text-black font-roboto font-normal text-2xl capatilize">
            Subcribe to our Newsletter
          </h1>
          <p className="tracking-wide text-gray-500 font-roboto font-normal text-sm leading-7 align-baseline tracking-normal my-0 py-0">
            Get e-mail updates about our latest shops and special offers
          </p>
        </div>
        <form className="flex-grow max-w-[800px]">
          <div className="flex gap-0 rounded-md overflow-hidden">
            <input
              type="text"
              id="voice-search"
              className="text-black text-sm focus:ring-none focus:border-none block p-2 w-full focus-visible:outline-none"
              placeholder="Enter an email address"
              required
            />
            <button
              type="submit"
              className="inline-flex items-center py-3 px-12 h-full font-medium text-white bg-primary"
            >
              <p className="tracking-widest	text-xs">Subscribe</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewsLetter;
