const VacancyDetails = ({ careerDetail }) => {
  return (
    <div className="bg-white p-4 sm:w-1/2  flex flex-col gap-12 ">
      <h2 className="text-2xl font-semibold mb-4">Vacancy Description</h2>
      <div
        dangerouslySetInnerHTML={{ __html: careerDetail?.description }}
        className="[&>p>span]:text-lg"
      ></div>
      <div>
        <span className="text-lg font-bold">Location :&nbsp;</span>
        <span>{careerDetail?.location || 'Not Specified'}</span>
      </div>
    </div>
  );
};

export default VacancyDetails;
