import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MapContainer from '../Checkout/MapContainer';
import { useForm } from 'react-hook-form';

function AddressFormModal({ closeModal, onFormSubmit }) {
  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm();
  const handleCancel = () => closeModal();
  const setAddress = (geoLocation, address) => {
    setValue('address', address);
    setValue('geoLocation', geoLocation);
  };
  const handleMapAdd = async () => {
    try {
      await trigger(); // Manually trigger form validation
    } catch (error) {
      console.log(error);
    }
    if (isValid) {
      onFormSubmit({ ...getValues() });
      closeModal();
    }
  };

  return (
    <div className="w-96 md:w-full">
      <div className="flex justify-end">
        <button
          type="button"
          onClick={handleCancel}
          className="mx-2.5 my-1 text-gray-500 hover:text-black rounded"
        >
          <CloseIcon />
        </button>
      </div>
      <div className="px-4 sm:px-8 max-h-[80vh] sm:min-w-[500px] md:min-w-[600px] lg:min-w-[760px] overflow-y-auto">
        <h1 className="font-bold uppercase">Please select a location</h1>
        <div className="mt-2 w-full">
          <MapContainer height="350px" setAddress={setAddress} />
        </div>
        <div className="my-6">
          <div className="form-control">
            <div className="flex flex-col items-baseline gap-2">
              <label htmlFor="address">
                Address <span className="text-red-600">*</span>
              </label>
              <input
                className="py-2.5 w-full px-4 border"
                disabled
                type="text"
                placeholder="Address from map"
                {...register('address', {
                  required: 'This field is required',
                })}
              />
            </div>
            {errors.address && (
              <div className="mt-1">
                <p className="text-xs text-red-600">{errors.address.message}</p>
              </div>
            )}
          </div>
          <div className="form-control my-2">
            <div className="flex flex-col items-baseline gap-2">
              <label htmlFor="addressDescription">
                Address description <span className="text-red-600">*</span>
              </label>
              <textarea
                className="py-2.5 w-full px-4 border"
                placeholder="Give details of you location"
                {...register('addressDescription', {
                  required: 'This field is required',
                })}
              />
            </div>
            {errors.addressDescription && (
              <div className="mt-1">
                <p className="text-xs text-red-600">
                  {errors.addressDescription.message}
                </p>
              </div>
            )}
          </div>
          <div className="pt-2">
            <button
              onClick={handleMapAdd}
              type="button"
              className="btn-primary py-1 px-6"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressFormModal;
