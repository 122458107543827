import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchData } from '../../../../services/RequestHandler/ApiRequest';
import { ReviewCard } from './UserReview';
import SubmitSpinner from '../../common/SubmitSpinner';

const Reviews = () => {
  const { slug } = useParams();
  const { branches } = useSelector(state => state.productCategoriesPage);
  const branch = branches?.find(branchObj => branchObj?.branch?.slug === slug);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(9e9);

  const fetchMoreData = async (limit = 10) => {
    const branchId = branch?.branch?.id;
    if (!branchId) {
      return;
    }
    setLoading(true);
    const response = await fetchData(
      `/reviews/branches/${branchId}?page=${page}&limit=${limit}`,
    );
    setTotalResults(response?.data?.total_results || 0);
    const newReviews = response?.data?.reviews || [];
    setData(prevData => {
      const existingIdsSet = new Set(prevData.map(review => review?.id));
      const uniqueReviews = newReviews.filter(
        review => !existingIdsSet.has(review?.id),
      );
      return [...prevData, ...uniqueReviews];
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchMoreData();
  }, [page]);

  return (
    <div className={`responsive-width top-[50px] pr-2 pl-2 `}>
      {data.map((item, index) => (
        <ReviewCard
          review={item?.review}
          rating={item?.rating}
          user={item?.user}
          key={item?.id}
          sn={index + 1}
          date={item?.updated_at}
        />
      ))}
      <div className="flex flex-col items-center pt-3">
        {loading ? (
          <div className="py-3 text-center">
            <SubmitSpinner />
            Loading Reviews...
          </div>
        ) : null}
        {!data?.length && !loading ? (
          <div className="py-3 text-center">No Reviews Yet</div>
        ) : (data?.length && data?.length >= totalResults) || loading ? null : (
          <button
            onClick={() => setPage(prev => prev + 1)}
            disabled={loading}
            className="w-sm uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-3 text-center"
          >
            Show More Reviews
          </button>
        )}
      </div>
    </div>
  );
};

export default Reviews;
