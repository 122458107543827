import React from 'react';
import Blog from './Blog';
import Animate from '../common/Animate/Animate';

function Blogs({ blogs, ...props }) {
  return (
    <div {...props}>
      {blogs.map(blog => (
        <div key={blog.id}>
          <Animate>
            <Blog blog={blog} />
          </Animate>
        </div>
      ))}
    </div>
  );
}

export default Blogs;
