/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageState: 'idle',
  aboutUs: {},
};

const aboutPageSlice = createSlice({
  name: 'aboutpage',
  initialState,
  reducers: {
    storeAboutPageData: (state, action) => {
      state.aboutUs = action.payload;
      state.pageState = 'success';
    },
    setAboutPagePending: state => {
      state.pageState = 'pending';
    },
  },
});

export const { storeAboutPageData } = aboutPageSlice.actions;
export default aboutPageSlice.reducer;
