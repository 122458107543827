import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SubmitSpinner from '../common/SubmitSpinner';

function CancelOrder({ closeModal, submitForm, orderDetails, loading }) {
  const handleCancel = () => closeModal();
  return (
    <div className="relative w-96 md:w-full bg-gray-50">
      <button
        onClick={handleCancel}
        className="absolute right-1 top-1 md:mr-1 mr-4  text-gray-600 hover:text-black rounded"
      >
        <CloseIcon />
      </button>
      <div className="flex items-center justify-center">
        <div className="p-6 sm:p-8 bg-gray-50 rounded-md  text-center overflow-y-auto md:mt-8 mt-8">
          <span className="text-gray-600 mt-4">
            Do you confirm to proceed with canceling order{' '}
            <span className="font-bold text-gray-800 mr-2">
              {orderDetails[0]?.order_code}
            </span>
            placed on
            <span className="ml-2">
              <span className="mr-1">
                {new Date(orderDetails[0]?.created_at).toLocaleDateString(
                  'en-US',
                  { year: 'numeric', month: 'short', day: 'numeric' },
                )}
              </span>
              at
              <span className="ml-1">
                {new Date(orderDetails[0]?.created_at).toLocaleTimeString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                  },
                )}
              </span>
            </span>
            ?
          </span>

          <div className="mt-6 flex justify-center gap-x-4">
            <button
              disabled={loading}
              onClick={() => submitForm(orderDetails[0]?.order_code)}
              type="button"
              className={`py-2.5 px-4 rounded-md border bg-primary text-white font-medium shadow-sm align-middle focus:outline-none transition-all text-sm ${loading ? 'opacity-70' : ''}`}
            >
              {loading ? <SubmitSpinner /> : null}Confirm
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="py-2.5 px-4 rounded-md font-semibold text-black hover:shadow  hover:bg-gray-00 focus:outline-none focus:ring-2 border-2 transition-all text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelOrder;
