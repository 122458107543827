import React from 'react';

import CustomCarousel from './Carousel';
import BannerSingle from './Banner';

function Banners({ banners, bannerNumber }) {
  return (
    <div className="border border-primary rounded-xl mx-4 p-2 pt-0 mt-5">
      <CustomCarousel>
        {banners?.map(banner => (
          <div key={banner.id}>
            <BannerSingle banner={banner} bannerNumber={bannerNumber} />
          </div>
        ))}
      </CustomCarousel>
    </div>
  );
}

export default Banners;
