import React from 'react';

const VideoPlayer = ({ videoId }) => {
  return (
    <div className="video-container flex justify-center overflow-hidden min-w-[208px] ">
      {/* The above style sets the aspect ratio to 16:9 (h:w = 9:16) */}
      <iframe
        title="YouTube Video"
        src={`https://www.youtube.com/embed/${videoId}`}
        allowFullScreen
        className=" w-full aspect-video lg:w-1/2"
      />
    </div>
  );
};

export default VideoPlayer;
