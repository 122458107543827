import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCareerPageData } from '../../../services/RequestHandler/ApiRequest';
import CareerCard from '../../components/Career/CareerCard';
import HeroSection from '../../components/HeroSection/HeroSection';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/common/Loading';
import Animate from '../../components/common/Animate/Animate';

const CareerList = () => {
  const dispatch = useDispatch();
  const { careerList, isCareerListLoading } = useSelector(
    state => state.careerPage,
  );
  useEffect(() => {
    if (!careerList.length) {
      dispatch(fetchCareerPageData());
    }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Career</title>
        <meta name="description" content="Career page" />
      </Helmet>
      <HeroSection
        title="Career"
        subtitle=""
        bgImage={
          'https://png.pngtree.com/thumb_back/fh260/back_our/20190620/ourmid/pngtree-food-seasoning-food-banner-image_169169.jpg'
        }
      />
      <section className="mt-2 responsive-width  text-gray-600 mb-10 overflow-hidden text-center ">
        <div className="p-5">
          <h1 className="text-3xl py-2 font-bold text-center  text-lavapaluAccent">
            Join Us
          </h1>
          <p className="text-center">
            Looking for more than just a job? Want to make a difference where
            you live and work? Explore what&apos;s possible with a career at
            Lava Palu. We help you pursue your professional dreams and cultivate
            meaningful relationships with the people and communities you
            support.
          </p>
          <hr className="mt-6  border-gray-300" />
        </div>
        <h1 className="text-3xl font-bold text-center text-lavapaluAccent mt-2 mb-4">
          Open Positions
        </h1>
        <Animate>
          {isCareerListLoading ? (
            <Loading className="py-8" />
          ) : (
            <div>
              {careerList.length === 0 ? (
                <span className="text-center">
                  No Vacancies available right now.
                </span>
              ) : (
                <div className="container mx-auto flex flex-row gap-6  ">
                  {careerList?.map((card, careerIndex) => (
                    <CareerCard
                      key={card?.slug}
                      title={card?.name}
                      content={card?.content}
                      to={card?.slug}
                      sn={careerIndex + 1}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </Animate>
      </section>
    </>
  );
};

export default CareerList;
