import Animate from '../common/Animate/Animate';

const BranchBanner = ({ branchDetail }) => {
  return (
    <section className="relative">
      <img
        src={branchDetail?.image_url}
        className="w-full h-full object-cover absolute top-0 left-0"
      />
      <div className="object-cover bg-black opacity-60 object-center h-full w-full absolute inset-0" />
      <Animate>
        <div className="mt-[10%] responsive-width mx-auto pr-4 pl-4 ">
          <div className="flex flex-row">
            <div
              className={`w-[100px] md:w-[200px] ${!branchDetail?.restros?.image_url ? ' bg-[#757575] h-[60px] md:h-[120px] ' : ''}`}
            >
              {!branchDetail?.restros?.image_url ? null : (
                <img
                  className="object-cover"
                  src={branchDetail?.restros?.image_url}
                  alt="image"
                />
              )}
            </div>
            <div className="ml-4 flex flex-col items-start justify-center">
              <div>
                <h1 className="text-white text-2xl md:text-4xl">
                  {branchDetail?.name || 'N/A'}
                </h1>
              </div>
              <div>
                <p className="text-white mb-2">
                  <span className="mr-1">
                    {branchDetail?.is_main_branch && 'Main Branch'}
                  </span>
                  <span></span>
                  <br />
                  <span className="mr-1"></span>
                  <span></span>
                </p>
              </div>
            </div>
          </div>
          <div className="py-4 flex flex-col md:flex-row justify-between">
            <div className="text-white flex flex-row md:flex-col">
              <div className="small-title">Minimum order:</div>
              <span className="">N/A</span>
            </div>
            <div className="flex flex-col  md:space-x-10 text-white md:flex-row">
              <div className="flex flex-row md:flex-col">
                <div className="small-title">Additional Service Charge:</div>
                <span className="">N/A</span>
              </div>
              <div className="flex flex-row md:flex-col">
                <div className="small-title">Additional Vat:</div>
                <span className="">N/A</span>
              </div>
            </div>
          </div>
        </div>
      </Animate>
    </section>
  );
};
export default BranchBanner;
