import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

function ConfirmOrderModal({ closeModal, submitForm }) {
  const handleCancel = () => closeModal();
  return (
    <div className="relative w-96 md:w-full">
      <button
        onClick={handleCancel}
        className="absolute right-2 top-1 text-gray-500 hover:text-black rounded"
      >
        <CloseIcon />
      </button>
      <div className="flex items-center justify-center">
        <div className="p-4 sm:p-10 bg-gray-50 rounded-md  text-center overflow-y-auto">
          <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500">
            <div className="text-amber-600">
              <QuestionMarkIcon />
            </div>
          </span>

          <h3 className="mb-2 text-2xl font-bold text-gray-800">
            Confirm order ?
          </h3>
          <p className="text-gray-500">
            Are you sure you would like to confirm these order?
          </p>

          <div className="mt-6 flex justify-center gap-x-4">
            <button
              onClick={submitForm}
              type="submit"
              className="py-2.5 px-4 rounded-md border btn-primary text-white font-medium shadow-sm align-middle focus:outline-none transition-all text-sm"
            >
              Confirm
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="py-2.5 px-4 rounded-md border font-semibold text-black hover:shadow  hover:bg-gray-100 focus:outline-none focus:ring-2 transition-all text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOrderModal;
