import React from 'react';
import ItemCard from './ItemCard';

function ItemsCardGroup({ items = [] }) {
  return (
    <div className="w-full grid-cols-1 xs:grid-cols-2 grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 gap-auto gap-y-8 p-1">
      {items.map(item => (
        <div key={item.id || item}>
          <ItemCard item={item} />
        </div>
      ))}
    </div>
  );
}

export default ItemsCardGroup;
