import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import NotFoundPage from './components/NotFoundPage';
import { HomePage } from './pages/HomePage';
import Layout from './components/Layout';
import About from './pages/About/About';
import BlogListPage from './pages/Blog/BlogListPage';
import BlogDetailPage from './pages/Blog/BlogDetailPage';
import Branch from './pages/Branch/Branch';
import { CareerPage, VacancyPage } from './pages/Career';
import {
  RegisterPage,
  LoginPage,
  LogoutPage,
  VerificationPage,
} from './pages/Auth';
import ProfilePage from './pages/Profile/ProfilePage';
import CheckoutPage from './pages/Checkout/CheckoutPage';
import Protected from '../utils/Protected';
import ScrollToTop from './components/ScrollToTop';
import ReviewAndRatings from './pages/ReviewAndRatings/ReviewAndRatings';
import Notification from './pages/Notification/Notification';
import ForgotPassword from './pages/Auth/VerificationPage/ForgotPassword';
import SearchResult from './pages/Search/SearchResult';
import PrivacyPolicy from './pages/TermsAndPolicy/PrivacyPolicy';
import TermsOfUses from './pages/TermsAndPolicy/TermsOfUses';

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

export function App() {
  const { isAuthenticated } = useSelector(state => state.login);
  return (
    // <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <BrowserRouter>
      <Helmet titleTemplate="Lavapalu - %s" defaultTitle="Lavapalu">
        <meta name="description" content="Lavapalu website" />
      </Helmet>
      <ScrollToTop />

      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/search" element={<SearchResult />} />
          <Route path="/blog" element={<BlogListPage />} />
          <Route path="/blog/:slug" element={<BlogDetailPage />} />
          <Route path="/restaurant/:slug" element={<Branch />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/career/:slug" element={<VacancyPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          <Route
            path="/checkout"
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <CheckoutPage />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <ProfilePage />
              </Protected>
            }
          />
          <Route
            path="/reviews/:slug"
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <ReviewAndRatings />
              </Protected>
            }
          />
          <Route
            path="/notifications"
            element={
              <Protected isAuthenticated={isAuthenticated}>
                <Notification />
              </Protected>
            }
          />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/terms-of-uses" element={<TermsOfUses />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/not-found" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
    // </Sentry.ErrorBoundary>
  );
}
