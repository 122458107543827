import { toast } from 'react-toastify';
import Api from '../Api';
import { removeAllItems } from '../../store/slice/cart';

export const deleteData = async (url, body) => {
  try {
    const res = await Api.delete(url, body);
    return res?.data;
  } catch (error) {
    console.error('Error occured while deleting data', error);
  }
};

export const removeCart = async (dispatch, payload) => {
  try {
    const { product_code, name, id } = payload;
    const response = await deleteData(`/carts/${product_code}`);
    if (response?.status === 204) {
      dispatch(removeAllItems({ itemId: id }));
      toast.warning(`${name} removed from cart`);
      return response;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};
