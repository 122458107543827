import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      query: search,
      title: 'Page View',
    });
  }, [pathname, search]);

  return null;
}
