import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { App } from './app';
import { store } from './store/index';
import './index.css';

const sentryLink = import.meta.env.SENTRY_SECRET_LINK;
const MEASUREMENT_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID;

ReactGA.initialize([{ trackingId: MEASUREMENT_ID }]);
Sentry.init({
  dns: sentryLink,
});

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
        <ToastContainer autoClose={2000} position="top-right" />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
);
