import React, { useEffect, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MyProfile from '../../components/ProfileTabs/MyProfile';
import FavoriteItems from '../../components/ProfileTabs/FavoriteItems';
import OrderHistory from '../../components/ProfileTabs/OrderHistory';

export const TabsItems = [
  {
    id: '1',
    icon: <PersonIcon />,
    title: 'My Profile',
    content: <MyProfile />,
  },
  {
    id: '2',
    icon: <ShoppingCartIcon />,
    title: 'Order History',
    content: <OrderHistory />,
  },
  {
    id: '3',
    icon: <FavoriteBorderIcon />,
    title: 'Favorites',
    content: <FavoriteItems />,
  },
];
const getActiveTab = tabParams => {
  const tabIndex = TabsItems.findIndex(item => {
    return item.title.toLowerCase().replace(' ', '-') === tabParams;
  });
  if (tabIndex === -1) return 0;
  else return tabIndex;
};
export default function Tabs() {
  const queryParams = new URLSearchParams(window.location.search);
  const tabParam = queryParams.get('tabs');
  const [activeTab, setActiveTab] = useState(getActiveTab(tabParam));

  const handleLocationChange = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const tabParam = queryParams.get('tabs');
    setActiveTab(() => getActiveTab(tabParam));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(
      'tabs',
      TabsItems[activeTab].title.toLowerCase().replace(' ', '-'),
    );
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  }, [activeTab]);
  useEffect(() => {
    // Listen for changes in window.location when the component mounts
    window.addEventListener('urlChanged', handleLocationChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('urlChanged', handleLocationChange);
    };
  }, []);

  return (
    <div className="w-full mx-auto">
      <div className="accordion-section grid grid-cols-4 gap-4 my-6">
        <div className="col-span-4 md:col-span-1">
          <div className="tab flex md:flex-col gap-4 justify-between md:px-4 md:px-0 ">
            {TabsItems.map((item, index) => (
              <div
                key={item.id}
                className={`${
                  activeTab === index
                    ? 'text-primary active border-b-2 md:border-b-0 md:border-l-2 border-primary'
                    : 'text-black md:border-b-0 border-b-2 md:border-l-2 border-white'
                } text-sm block py-1 md:px-2 hover:cursor-pointer hover:border-primary transition-all`}
                onClick={() => setActiveTab(index)}
                onKeyDown={() => setActiveTab(index)}
                role="button"
                tabIndex={0}
              >
                <p className="">
                  <span>{item.icon}</span> {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-4 md:col-span-3 md:border-l md:pl-4 border-t md:border-t-0">
          <div className="active-tab">{TabsItems[activeTab].content}</div>
        </div>
      </div>
    </div>
  );
}
