import React from 'react';
import SpinIcon from '../../../assets/svg/spinner.svg';

function Spinner() {
  return (
    <div className="fixed top-0 left-0 bg-black bg-opacity-20 z-[1000000000] w-full h-[100vh]">
      <div className="flex justify-center h-[90%] items-center ">
        <div className="h-16 w-16 animate-spin text-white">
          <SpinIcon />
        </div>
      </div>
    </div>
  );
}

export default Spinner;
