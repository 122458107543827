import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Animate from '../common/Animate/Animate';

function HomePageBanner({ banner }) {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const isVideo =
    banner?.image_url?.toLowerCase()?.endsWith('.mp4') ||
    banner?.image_url?.toLowerCase()?.endsWith('.webm');
  const handleQueryChange = e => {
    setQuery(e.target.value);
  };
  const handleSearch = e => {
    e.preventDefault();
    navigate(`/search?query=${query}`);
  };
  return (
    <div className="h-[95vh] bg-secondary relative">
      {isVideo ? (
        <video
          src={banner.image_url}
          autoPlay
          loop
          muted
          loading="lazy"
          className="object-cover object-center h-full w-full absolute inset-0"
        />
      ) : (
        <>
          {banner.image_url ? (
            <img
              src={`${banner.image_url}`}
              alt="Background Image"
              className="object-cover object-center h-full w-full absolute inset-0"
            />
          ) : (
            <div className="object-cover bg-secondary object-center h-full w-full absolute inset-0" />
          )}
        </>
      )}
      <div className="object-cover bg-black opacity-40 object-center h-full w-full absolute inset-0" />

      <Animate className="flex flex-col gap-10 items-center justify-center h-full relative">
        <p className="uppercase text-sm text-white tracking-[0.2rem] text-center">
          {banner?.name}
        </p>

        <div className=" w-[90%] xs:w-[400px] sm:w-[500px] md:w-[500px] lg:w-[600px] xl:w-[800px] 2xl:w-[1200] ">
          <form
            onSubmit={handleSearch}
            className="rounded-lg flex gap-0 items-center rounded-md overflow-hidden shadow-dark"
          >
            <div className="relative w-full">
              <input
                value={query}
                type="text"
                id="voice-search"
                className="text-gray-900 focus:ring-none text-sm focus:border-none block w-full p-3 focus-visible:outline-none "
                placeholder="Search Restaurant or items"
                required
                onChange={handleQueryChange}
              />
            </div>
            <button
              type="submit"
              className="inline-flex items-center py-3 px-14 h-full text-sm font-light text-black bg-primary "
            >
              Search
            </button>
          </form>
        </div>
      </Animate>
    </div>
  );
}

export default HomePageBanner;
