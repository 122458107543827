const HorizontalTabItem = ({ label, active, onClick }) => {
  return (
    <button
      className={`px-4 py-3.5  text-sm leading-5  cursor-pointer   transition-all duration-800 hover:scale-105 hover:text-primary ${
        active
          ? 'font-bold border-b-[3px] border-primary  text-primary  focus:border-primary focus:text-primary  '
          : 'font-light border-b-[3px] border-transparent'
      }`}
      onClick={onClick}
      role="tab"
    >
      {label}
    </button>
  );
};
export default HorizontalTabItem;
