import React, { useState, useEffect } from 'react';
import useAuthenticatedAction from '../../../../utils/auth';
import { postCart } from '../../../../services/RequestHandler/ApiPostRequest';
import { useNavigate } from 'react-router-dom';

const CountdownTimer = ({ onDealEnd, endTime, title, banner }) => {
  const navigate = useNavigate();
  const { handleAuthenticatedAction } = useAuthenticatedAction();
  const handleAddToCart = async () => {
    if (banner?.product) {
      await handleAuthenticatedAction(postCart, {
        ...banner?.product,
        unit: 1,
      });
    } else {
      navigate(`/restaurant/${banner?.branch?.slug}`);
    }
  };
  let interval;
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  function formatNumber(number) {
    return number < 10 ? `0${number}` : number;
  }
  function calculateTimeRemaining() {
    const currentTime = new Date().getTime();
    const targetTime = new Date(endTime).getTime();
    const timeDiff = targetTime - currentTime;
    if (timeDiff <= 0) {
      clearInterval(interval);
      onDealEnd();
      return {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    }
    const seconds = formatNumber(Math.floor((timeDiff / 1000) % 60));
    const minutes = formatNumber(Math.floor((timeDiff / 1000 / 60) % 60));
    const hours = formatNumber(Math.floor((timeDiff / (1000 * 60 * 60)) % 24));
    const days = formatNumber(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  useEffect(() => {
    function updateTimer() {
      setTimeRemaining(calculateTimeRemaining());
    }
    interval = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return (
    <div>
      <div className="flex justify-between items-center mt-7 mb-2 px-2 ">
        <div className="flex flex-col md:flex-row items-center gap-2">
          <div className="flex gap-1">
            <p>Ending in</p>
            {timeRemaining.days !== '00' && (
              <p className="h-8 w-8 flex justify-center items-center bg-primary rounded text-white">
                {timeRemaining.days}
              </p>
            )}
            <p className="h-8 w-8 flex justify-center items-center bg-primary rounded text-white">
              {timeRemaining.hours}
            </p>
            <p className="h-8 w-8 flex justify-center items-center bg-primary rounded text-white">
              {timeRemaining.minutes}
            </p>
            <p className="h-8 w-8 flex justify-center items-center bg-primary rounded text-white">
              {timeRemaining.seconds}
            </p>
          </div>
        </div>
        <div>
          <p className="text-xs md:text-sm lg:text-xl uppercase font-bold">
            {title}
          </p>
        </div>
        <button
          className="px-2 md:px-4 py-1.5 border border-primary  hover:bg-white hover:text-primary text-xs md:text-sm  bg-primary text-white rounded-xl"
          onClick={handleAddToCart}
        >
          Order now
        </button>
      </div>
    </div>
  );
};

export default CountdownTimer;
