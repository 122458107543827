import React, { useState } from 'react';

import Animate from '../common/Animate/Animate';
import { Link } from 'react-router-dom';

function RestroCard({ restro = {} }) {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  return (
    <Animate>
      <div className="min-w-[200px] w-full max-w-[500px] aspect-3/2 px-3 z-0">
        <div
          className="bg-white border border-gray-200 hover:shadow-lg"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="overflow-hidden">
            <Link to={`/restaurant/${restro.slug}?tabs=tabs-menu`}>
              {restro?.restros?.image_url ? (
                <img
                  src={restro.restros.image_url}
                  alt={restro.name}
                  className={`${
                    hovered ? 'scale-105' : ''
                  } object-cover transition duration-500 cursor-pointer`}
                />
              ) : (
                <div
                  className={`${
                    hovered ? 'scale-105' : ''
                  } bg-secondary h-[200px] flex items-center justify-center transition duration-500 cursor-pointer`}
                >
                  <h1 className="text-black text-2xl text-gray-500">Restro</h1>
                </div>
              )}
            </Link>
          </div>
          <div className="p-5 flex flex-col">
            <Link to={`/restaurant/${restro.slug}`}>
              <p className="text-black text-sm font-roboto font-light text-lg leading-7 tracking-tight align-baseline tracking-wider whitespace-nowrap	overflow-hidden w-full text-ellipsis">
                {restro.name}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </Animate>
  );
}
export default RestroCard;
