import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
  postCart,
  postFavourite,
} from '../../../services/RequestHandler/ApiPostRequest';
import useAuthenticatedAction from '../../../utils/auth';

function FavoriteItems() {
  const { favoriteItems } = useSelector(state => state.favorite);
  const { handleAuthenticatedAction } = useAuthenticatedAction();
  const [removingFav, setRemovingFav] = useState(false);

  const handleAddToCart = async payload => {
    await handleAuthenticatedAction(postCart, { ...payload, unit: 1 });
  };
  const handleFavItemRemoveClick = async payload => {
    setRemovingFav(true);
    await handleAuthenticatedAction(postFavourite, {
      ...payload,
      removeFavourite: true,
    });
    setRemovingFav(false);
  };

  return (
    <div>
      <h3 className="uppercase text-sm font-bold text-gray-500 tracking-tighter	md:pt-0 pt-4">
        Favorite Items
      </h3>
      <div className="my-4">
        {favoriteItems.map(item => (
          <div
            key={item.id}
            className="py-3 flex justify-between items-center gap-2"
          >
            <div className="flex gap-4">
              <img src={item.image_url} alt="" className="h-10 aspect-3/2" />
              <div className="flex flex-col gap-2">
                <h3 className="text-sm text-green-500 hover:text-green-700">
                  {item.name}
                </h3>
                <p className="text-xs text-green-500">Rs. {item.amount}</p>
              </div>
            </div>
            <div className="flex gap-1 xs:gap-4 lg:gap-12">
              <button
                className="btn-primary text-xs py-1 px-4"
                onClick={() => handleAddToCart(item)}
              >
                <p className="hidden sm:block">Add to cart</p>
                <span className="sm:hidden">
                  <ShoppingCartIcon fontSize="small" />
                </span>
              </button>

              <button
                onClick={() => handleFavItemRemoveClick(item)}
                className="opacity-50 hover:opacity-70"
                disabled={removingFav}
                style={removingFav ? { cursor: 'not-allowed' } : null}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        ))}
        {favoriteItems.length < 1 && (
          <h1 className="text-sm">No favorite items!</h1>
        )}
      </div>
    </div>
  );
}

export default FavoriteItems;
