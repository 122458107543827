import React from 'react';
import { Link } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function ConfirmOrderModal({ billingAddress }) {
  return (
    <div className="flex items-center justify-center w-96 md:w-full">
      <div className="p-4 sm:p-10 bg-gray-50 rounded-md  text-center overflow-y-auto">
        <span className="mb-4 inline-flex justify-center items-center w-[64px] h-[64px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500">
          <div className="text-green-600">
            {/* <DoneOutlineIcon className="text-green-600 fill-green-600" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 48 48"
            >
              <linearGradient
                id="HoiJCu43QtshzIrYCxOfCa_VFaz7MkjAiu0_gr1"
                x1="21.241"
                x2="3.541"
                y1="39.241"
                y2="21.541"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".108" stopColor="#0d7044"></stop>
                <stop offset=".433" stopColor="#11945a"></stop>
              </linearGradient>
              <path
                fill="url(#HoiJCu43QtshzIrYCxOfCa_VFaz7MkjAiu0_gr1)"
                d="M16.599,41.42L1.58,26.401c-0.774-0.774-0.774-2.028,0-2.802l4.019-4.019	c0.774-0.774,2.028-0.774,2.802,0L23.42,34.599c0.774,0.774,0.774,2.028,0,2.802l-4.019,4.019	C18.627,42.193,17.373,42.193,16.599,41.42z"
              ></path>
              <linearGradient
                id="HoiJCu43QtshzIrYCxOfCb_VFaz7MkjAiu0_gr2"
                x1="-15.77"
                x2="26.403"
                y1="43.228"
                y2="43.228"
                gradientTransform="rotate(134.999 21.287 38.873)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#2ac782"></stop>
                <stop offset="1" stopColor="#21b876"></stop>
              </linearGradient>
              <path
                fill="url(#HoiJCu43QtshzIrYCxOfCb_VFaz7MkjAiu0_gr2)"
                d="M12.58,34.599L39.599,7.58c0.774-0.774,2.028-0.774,2.802,0l4.019,4.019	c0.774,0.774,0.774,2.028,0,2.802L19.401,41.42c-0.774,0.774-2.028,0.774-2.802,0l-4.019-4.019	C11.807,36.627,11.807,35.373,12.58,34.599z"
              ></path>
            </svg>
          </div>
        </span>

        <h3 className="mb-2 text-2xl font-bold text-gray-800">Order Placed!</h3>
        <p className="text-gray-500">
          Thank you for your order, you will received it shortly.
        </p>

        <div className="flex flex-col gap-6 bg-gray-800 rounded px-6 py-3 my-4 text-gray-300">
          <h3 className="text-xl font-bold">
            Your order will be send to this address:
          </h3>
          <div className="flex flex-col gap-3 justify-start px-2 text-start ">
            <div className="text-sm flex flex-row items-center gap-2">
              <span>
                <PersonOutlineIcon fontSize="small" />
              </span>
              <p>{billingAddress.fullName}</p>
            </div>
            <div className="text-sm flex flex-row items-center gap-2">
              <span>
                <PhoneIcon fontSize="small" />
              </span>
              <p>{billingAddress.phoneNumber}</p>
            </div>
            <div className="text-sm flex flex-row items-center gap-2">
              <span>
                <LocationOnIcon fontSize="small" />
              </span>
              <p>{billingAddress.address}</p>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-center gap-x-4">
          <Link
            to="/profile?tabs=order-history"
            className="py-2.5 px-4 rounded-md border bg-green-600 hover:bg-green-700 text-white font-medium shadow-sm align-middle focus:outline-none transition-all text-sm"
          >
            Check history
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ConfirmOrderModal;
