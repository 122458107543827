import Api from '../Api';
import { storeBanners, storeHomePageData } from '../../store/slice/homepage';
import { storeAboutPageData } from '../../store/slice/aboutpage';
import {
  storeBlogPageData,
  setBlogPagePending,
} from '../../store/slice/blogpage';
import {
  storeCareerDetailData,
  storeCareerPageData,
  careerListLoading,
} from '../../store/slice/careerpage';
import { storeBranchList } from '../../store/slice/productCategories';
import {
  startOrdersLoading,
  stopOrdersLoading,
  stopLoadMoreOrders,
  orders,
} from '../../store/slice/orderHistory';
import {
  updateNotifications,
  refreshNotifications,
} from '../../store/slice/notification';

export const buildQueryParams = (params = {}) =>
  Object.keys(params)
    .filter(key => params[key])
    .map(key =>
      encodeURIComponent(params[key])
        ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        : '',
    )
    .join('&');

export const fetchData = async url => {
  try {
    const res = await Api.get(url);
    return res?.data;
  } catch (error) {
    console.error('Error occurred while fetching data', error);
    return;
  }
};

export const fetchHomePageData = () => async dispatch => {
  const data = await fetchData('/homepage/promotion-items');
  dispatch(storeHomePageData(data?.data));
};
export const fetchHomePageBanner = () => async dispatch => {
  const data = await fetchData('/homepage/banner-items');
  dispatch(storeBanners(data?.data));
};

export const fetchAboutPageData = () => async dispatch => {
  const data = await fetchData('/about-us');
  dispatch(storeAboutPageData(data?.data));
};
export const fetchBlogPageData = () => async dispatch => {
  dispatch(setBlogPagePending());
  const data = await fetchData('/blogs');
  dispatch(storeBlogPageData(data?.data));
};
export const fetchBlogBySlug = async slug => {
  const data = await fetchData(`/blogs/${slug}`);
  return data?.data || {};
};

export const fetchCareerPageData = () => async dispatch => {
  dispatch(careerListLoading());
  const data = await fetchData('/career/vacancies');
  dispatch(storeCareerPageData(data?.data));
};
export const fetchCareerDetailData = slug => async dispatch => {
  const data = await fetchData(`/career/vacancies/${slug}`);
  dispatch(storeCareerDetailData(data));
};

export const fetchProductCategoriesByBranch = slug => async dispatch => {
  const data = await fetchData(`/branches/${slug}/products`);
  if (data?.status === 200) {
    dispatch(storeBranchList(data));
    return data?.data?.branch?.id;
  } else {
    return false;
  }
};

export const fetchOrderHistory =
  (page = 1, limit = 20) =>
  async dispatch => {
    dispatch(startOrdersLoading());
    const data = await fetchData(`/orders?page=${page}&limit=${limit}`);
    if (data?.status === 200) {
      dispatch(orders(data?.data));
    }
    if (data?.status === 404) {
      dispatch(stopLoadMoreOrders());
    }
    if (data?.data?.notifications?.length) {
      dispatch(
        refreshNotifications({ notifications: data?.data?.notifications }),
      );
    }
    dispatch(stopOrdersLoading());
  };

export const fetchProductReview = async slug => {
  const data = await fetchData(`/notifications/${slug}`);
  return data;
};

export const markNotification = async (dispatch, slug) => {
  const data = await fetchData(`/notifications/mark-as-read/${slug}`);
  if (data?.status === 201) {
    dispatch(updateNotifications({ notificationSlug: slug }));
  }
};

export const checkPromoCodeValidation = async promo_code => {
  const data = await fetchData(`/get-promo-code/${promo_code}`);
  return data;
};
export const getBranchBySearchParam = async params => {
  const query = buildQueryParams(params);
  const data = await fetchData(`/find/branches?${query}`);
  return data?.data?.branches;
};
export const getTermsOfUses = async () => {
  const data = await fetchData('/terms-of-service');
  return data?.data?.description;
};
export const getPrivacyPolicy = async () => {
  const data = await fetchData('/privacy-policy');
  return data?.data?.description;
};
