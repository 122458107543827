import React from 'react';
import RestroCard from './RestroCard';

function RestrosCardGroup({ restros = [] }) {
  return (
    <div className="w-full grid-cols-1 xs:grid-cols-2 grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 gap-auto gap-y-8 p-1">
      {restros.map(restro => (
        <div key={restro.id || restro}>
          <RestroCard restro={restro} />
        </div>
      ))}
    </div>
  );
}

export default RestrosCardGroup;
