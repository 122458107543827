import React from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { Link } from 'react-router-dom';
import { getFormattedDate } from '../../../utils/getFormattedDate';

function RecentBlog({ blog }) {
  return (
    <div className="blogs-list flex gap-4 items-center">
      <Link to={`/blog/${blog.slug}`}>
        <img
          className="h-[80px] w-[80px] lg:w-[170px] object-cover"
          src={blog.image_url}
          alt={blog.name}
        />
      </Link>

      <div className="flex flex-col justify-between gap-2 mt-4">
        <div className="blog-title">
          <Link to={`/blog/${blog.slug}`}>
            <p className="font-medium text-base tracking-wider hover:text-green-800">
              {blog.name}
            </p>
          </Link>
        </div>
        <div className="flex gap-2 text-xs text-gray-900">
          <div className="date">{getFormattedDate(blog.created_at)}</div>
          <div className="user hidden"> Admin</div>
          <div className="comments text-gray-400 hidden">
            <CommentIcon fontSize="inherit" />
            <span>3</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentBlog;
