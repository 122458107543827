import React from 'react';
import Animate from '../common/Animate/Animate';
import RestrosCardGroup from './RestroCardGroup';

function RestroWithHeading({ heading, restrosList = [] }) {
  return (
    <div className="mt-12">
      <Animate className="uppercase pl-4 text-black mb-2  font-roboto font-semibold text-lg leading-15 align-baseline tracking-normal my-0">
        {heading}
      </Animate>
      <div>
        <RestrosCardGroup restros={restrosList} />
      </div>
    </div>
  );
}

export default RestroWithHeading;
