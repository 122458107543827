import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../../../services/RequestHandler/ApiRequest';
import VacancyDetails from '../../components/Career/VacancyDetails';
import VacancyApplicationForm from '../../components/Career/VacancyApplicationForm';
import { storeCareerDetailData } from '../../../store/slice/careerpage';
import Loading from '../../components/common/Loading';
import { toast } from 'react-toastify';

const Vacancy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentVacancy, setCurrentVacancy] = useState({});
  const { careerList } = useSelector(state => state.careerPage);
  useEffect(() => {
    const fetchVacancyDetail = async () => {
      try {
        const vacancyOpened = careerList?.find(
          vacancyObj => vacancyObj?.slug === slug,
        );
        if (!vacancyOpened) {
          const data = await fetchData(`/career/vacancies/${slug}`);
          if (data?.status === 200) {
            dispatch(storeCareerDetailData(data));
          }
          if (data.status === 404) {
            navigate('/not-found');
          }
          setCurrentVacancy(data?.data);
        } else {
          await new Promise(resolve => setTimeout(resolve, 250));
          setCurrentVacancy(vacancyOpened);
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    };
    fetchVacancyDetail();
  }, [slug]);

  if (loading) return <Loading className="py-24 my-24" />;
  return (
    <div className="mt-12 responsive-width  text-gray-600 mb-10 overflow-hidden ">
      <div className="my-6 rounded-md flex flex-col sm:flex-row">
        <>
          {/* Vacancy Details */}
          <VacancyDetails careerDetail={currentVacancy} />
          {/* Application Form */}
          <VacancyApplicationForm
            questions={currentVacancy?.questions}
            slug={slug}
          />
        </>
      </div>
    </div>
  );
};

export default Vacancy;
