import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroSection from '../../components/HeroSection/HeroSection';
import { fetchBlogPageData } from '../../../services/RequestHandler/ApiRequest';
import Blogs from '../../components/Blog/Blogs';
import RecentBlogs from '../../components/Blog/RecentBlogs';
import Loading from '../../components/common/Loading';
import { Helmet } from 'react-helmet-async';

function BlogListPage() {
  const dispatch = useDispatch();
  const { blogs, recentBlogs, pageState } = useSelector(
    state => state.blogPage,
  );
  useEffect(() => {
    dispatch(fetchBlogPageData());
  }, [dispatch]);

  return (
    <div className="font-roboto ">
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Blog page" />
      </Helmet>
      <HeroSection title="Blogs" subtitle="Blogs" bgImage="" />
      {pageState === 'pending' && !blogs.length ? (
        <Loading className="py-24" />
      ) : (
        <div>
          <div className="responsive-width my-20">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="col-span-full lg:col-span-2">
                {blogs?.length ? <Blogs blogs={blogs} /> : <h1>No Blogs!!</h1>}
              </div>
              <div className="recent-blogs col-span-full lg:col-span-1 pt-4">
                <h1 className="text-xl">Recents Blogs</h1>
                {recentBlogs?.length ? (
                  <RecentBlogs blogs={recentBlogs} />
                ) : (
                  <h1>No Blogs!!</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogListPage;
