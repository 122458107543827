import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import loginReducer from './slice/login';
import homePageReducer from './slice/homepage';
import cartReducer from './slice/cart';
import aboutPageReducer from './slice/aboutpage';
import blogPageReducer from './slice/blogpage';
import careerListReducer from './slice/careerpage';
import productCategoriesReducer from './slice/productCategories';
import favoriteItemsReducer from './slice/favoriteItems';
import ordersReducer from './slice/orderHistory';
import googleMapReducer from './slice/GoogleMapApi';
import notificationReducer from './slice/notification';

const rootReducer = combineReducers({
  login: loginReducer,
  homePage: homePageReducer,
  cart: cartReducer,
  aboutPage: aboutPageReducer,
  blogPage: blogPageReducer,
  careerPage: careerListReducer,
  productCategoriesPage: productCategoriesReducer,
  favorite: favoriteItemsReducer,
  orders: ordersReducer,
  googleMap: googleMapReducer,
  notification: notificationReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
