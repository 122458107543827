import React from 'react';
import SpinIcon from '../../../assets/svg/spinner.svg';

function Loading({ className, ...props }) {
  return (
    <div {...props} className={`w-full ${className}`}>
      <div className="w-full flex justify-center items-center">
        <div className="h-16 w-16 animate-spin text-white">
          <SpinIcon />
        </div>
      </div>
    </div>
  );
}

export default Loading;
