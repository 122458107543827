import React, { useState } from 'react';
import Tabs from './Tab';
import { Helmet } from 'react-helmet-async';
import Modal from '../../components/common/Modal';
import LogoutModal from '../../components/Modal/LogoutModal';

function ProfilePage() {
  const [modalOpen, setModalOpen] = useState(false);
  const hideModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);
  return (
    <div className="font-roboto">
      <Helmet>
        <title>Profile</title>
        <meta name="description" content="Profile page" />
      </Helmet>
      <div className="bg-gray-100 py-4 border-b border-gray-300 shadow">
        <div className="responsive-width px-2 flex justify-between items-center pt-8">
          <h1 className="text-4xl tracking-widest text-gray-700">Profile</h1>
          <button
            onClick={openModal}
            className="bg-primary text-white rounded px-8 py-1.5"
          >
            Logout
          </button>
        </div>
      </div>
      <div className="responsive-width px-2">
        <Tabs />
      </div>
      <Modal isOpen={modalOpen} onRequestClose={hideModal}>
        <LogoutModal closeModal={hideModal} />
      </Modal>
    </div>
  );
}

export default ProfilePage;
