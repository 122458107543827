import * as React from 'react';
import { Helmet } from 'react-helmet-async';

function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <div className="h-[100vh] flex items-center justify-center flex-col min-h-[320px]">
        <div className="mt-[-8vh] font-bold text-black text-lg">
          4
          <span role="img" className="text-lg" aria-label="Crying Face">
            😢
          </span>
          4
        </div>
        <p>Page not found.</p>
      </div>
    </>
  );
}
export default NotFoundPage;
