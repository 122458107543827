import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import VerticalTabItem from '../VerticalTabItem';
import CategoryContent from '../CategoryContent';

const Menu = ({ productCategoriesList = [] }) => {
  const [activeTab, setActiveTab] = useState('tabs-home');
  const [searchText, setSearchText] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const handleTabClick = tabId => {
    setActiveTab(tabId);
    scrollToTabContent(tabId);
  };

  const scrollToTabContent = (tabId, additionalOffsetPercent = 0.125) => {
    const element = document.getElementById(tabId);

    if (element) {
      const windowHeight = window.innerHeight;
      const additionalOffset = windowHeight * additionalOffsetPercent;

      const topOffset =
        element.offsetTop +
        240 +
        additionalOffset -
        (tabId === productCategoriesList?.product_categories[0]?.category_slug
          ? 100
          : 0);

      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };

  const onSubmit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    const handleSearch = () => {
      const filteredCategoriesResult =
        productCategoriesList?.product_categories?.filter(pCategory => {
          const categoryMatch = pCategory?.category_name
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase());

          const productMatch = pCategory?.products?.some(product =>
            product?.name?.toLowerCase()?.includes(searchText?.toLowerCase()),
          );

          return categoryMatch || productMatch;
        }) || [];

      setFilteredCategories(filteredCategoriesResult);
    };
    handleSearch();
  }, [searchText]);

  return (
    <div className="container responsive-width mx-auto">
      <div
        className={`md:visible invisible w-0 md:w-[300px] md:ml-[0px] ml-[-500px] top-[100px]   sticky   duration-150 ease-linear font-roboto mt-4 `}
        role="tabpanel"
        aria-labelledby="tabs-menu-tab"
        id="tabs-menu"
        style={{ alignItems: 'center' }}
      >
        <nav
          className="flex flex-col space-y-2 overflow-y-auto  cursor-pointer  "
          aria-label="Tabs"
          role="tablist"
          data-te-nav-ref
          style={{
            height: '90vh',
            overflowX: 'hidden',
            position: 'sticky',
            top: '4.5rem',
            overflowY: 'auto',
          }}
        >
          <h1 className="text-lg font-bold ml-20 my-4 text-gray-500  flex flex-row">
            🔥&nbsp;
            <div>
              <span className="mx-auto">Categories</span>
              <span>
                <hr />
              </span>
            </div>
          </h1>
          {productCategoriesList?.product_categories?.map(pCategory => (
            <VerticalTabItem
              key={pCategory?.category_slug}
              id={pCategory?.category_slug}
              label={pCategory?.category_name}
              active={activeTab === pCategory?.category_slug}
              onClick={() => handleTabClick(pCategory?.category_slug)}
            />
          ))}
        </nav>
      </div>
      <div
        className="md:ml-[300px] top-0 flex-grow  max-w-[768px] min-h-[90vh] px-2 md:px-8  "
        style={{ marginTop: '-90vh' }}
      >
        <div className=" flex-grow text-center ">
          <form className=" py-4 mb-4 " onSubmit={onSubmit} autoComplete="off">
            <div className=" flex gap-0 items-center rounded-md overflow-hidden shadow-dark">
              <div className="relative w-full">
                <input
                  type="text"
                  id="search-id"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value.trimStart())}
                  className="text-gray-900 focus:ring-none text-sm focus:border-none block w-full p-3 focus-visible:outline-none "
                  placeholder="Search Food Items"
                  autoComplete="false"
                />
              </div>
              {searchText && (
                <button
                  type="button"
                  onClick={() => setSearchText('')}
                  className="w-10 h-10 p-1 cursor-pointer text-2xl text-[gray] transition-transform transform hover:scale-105 active:scale-95"
                >
                  x
                </button>
              )}
              <button
                type="submit"
                className="inline-flex items-center py-3 px-5 h-full text-sm font-light text-black bg-primary "
              >
                <SearchIcon />
              </button>
            </div>
          </form>
          {productCategoriesList?.product_categories?.length > 0 ? (
            <>
              {filteredCategories?.length > 0 ? (
                filteredCategories.map(pCategory => (
                  <CategoryContent
                    key={pCategory?.category_slug}
                    id={pCategory?.category_slug}
                    label={pCategory?.category_name}
                    active={activeTab === pCategory?.category_slug}
                    onClick={() => handleTabClick(pCategory?.category_slug)}
                    products={pCategory?.products}
                    searchText={searchText}
                  />
                ))
              ) : (
                <span className="text-center">No matching products found.</span>
              )}
            </>
          ) : (
            <span className="text-center">
              No Products Available At The Moment
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Menu;
