import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { postSendCode } from '../../../../services/RequestHandler/AuthRequest';
import SubmitSpinner from '../../../components/common/SubmitSpinner';

const EmailInput = ({ handleCurrentPage, setGlobalEmail }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async payload => {
    setIsSubmitting(true);
    try {
      setGlobalEmail(payload?.email);
      const response = await postSendCode(payload);
      if (response) {
        await handleCurrentPage(2);
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-[93vh]">
      <div className="bg-white px-6 pt-10 pb-9 sm:shadow-xl mx-auto border sm:rounded-2xl min-w-[25rem] ">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-8">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">
              <p>Forgot Password</p>
            </div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>Please enter your email before further proceeding</p>
            </div>
          </div>
          <div>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-row gap-4 justify-between">
                <div className="w-full flex flex-col gap-2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Email here..."
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <span className="text-red-600 text-sm">Email is required</span>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`flex flex-row ${
                  isSubmitting ? 'cursor-normal' : 'cursor-pointer'
                } items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm ${
                  isSubmitting ? 'bg-gray-400' : 'bg-primary'
                }`}
              >
                {isSubmitting ? <SubmitSpinner /> : null}
                <span className="py-2">Send Reset Code</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailInput;
