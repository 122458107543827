import React, { useState, useEffect, useRef } from 'react';
import { postVacancyApplication } from '../../../services/RequestHandler/ApiPostRequest';
import useAuthenticatedAction from '../../../utils/auth';
import { useSelector } from 'react-redux';
import Spinner from '../../components/common/Spinner';
import { toast } from 'react-toastify';
import Animate from '../common/Animate/Animate';

const InputField = ({ question, value, handleInputChange }) => {
  const handleChange = event => {
    handleInputChange(question, event.target.value);
  };

  return (
    <div className="mb-4 w-full">
      <label htmlFor={question} className="block font-medium mb-1">
        {question}
      </label>
      <input
        type="text"
        id={question}
        name={question}
        value={value}
        onChange={handleChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-primary focus:border-primary block w-full p-2.5"
        required
        autoComplete="off"
      />
    </div>
  );
};

const VacancyApplicationForm = ({ questions = [], slug }) => {
  const initialFormData = Object.fromEntries(questions?.map(key => [key, '']));
  const [formData, setFormData] = useState(initialFormData);
  const fileRef = useRef(null);
  const [cvFile, setCvFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useSelector(state => state.login);
  const { handleAuthenticatedAction } = useAuthenticatedAction();

  const handleInputChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCvChange = event => {
    const file = event.target.files[0];
    if (file) {
      if (file.size >= 2 * 1000 * 1000) {
        toast.warning(
          'File size exceeds the limit of 2 MB. Please choose a smaller file.',
        );
        fileRef.current.value = null;
      }
    }
    setCvFile(file);
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      setLoading(true);
      const formDataWithCv = new FormData();
      formDataWithCv.append('cv_file', cvFile);
      const questionsPost = [];
      const answers = [];
      for (const key in formData) {
        if (key !== 'cv_file') {
          questionsPost.push(key);
          answers.push(formData[key]);
        }
      }
      formDataWithCv.append('questions', JSON.stringify(questionsPost));
      formDataWithCv.append('answers', JSON.stringify(answers));
      formDataWithCv.append('name', JSON.stringify(answers[0]));

      const res = await postVacancyApplication(slug, formDataWithCv);
      if (res) {
        setFormData(initialFormData);
        setCvFile(null);
        if (fileRef?.current) {
          fileRef.current.value = null;
        }
      }
    } catch (err) {
      toast.error(err?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [formData]);

  if (!isAuthenticated) {
    return (
      <div
        className="bg-gray-100 p-4 sm:w-1/2 rounded-md sm:ml-auto flex items-start"
        style={{ height: 'fit-content !important' }}
      >
        <Animate>
          <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
            <h2 className="text-2xl font-semibold mb-6">
              Login to Apply for this Vacancy
            </h2>
            <button
              onClick={() => {
                handleAuthenticatedAction(() => {});
              }}
              className="uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 text-center"
            >
              Login
            </button>
          </div>
        </Animate>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 p-4 sm:w-1/2 rounded-md sm:ml-auto ml-2 mr-2">
      <Animate>
        {loading ? <Spinner /> : null}
        <h2 className="text-2xl font-semibold mb-4">Apply for this post</h2>
        <form onSubmit={handleSubmit}>
          {questions?.map(question => (
            <InputField
              key={question}
              question={question}
              value={formData[question]}
              handleInputChange={handleInputChange}
            />
          ))}
          <div className="mb-4">
            <label htmlFor="cv" className="block font-medium mb-1">
              Upload your CV{' '}
              <span className="font-bold">{'( Max size: 2MB )'}</span>
            </label>
            <input
              ref={fileRef}
              type="file"
              name="cv"
              id="cv"
              accept=".pdf,.doc,.docx"
              onChange={handleCvChange}
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="btn bg-primary text-white py-2 px-20 rounded-md hover:scale-105 active:bg-[#E89020]"
            >
              Submit
            </button>
          </div>
        </form>
      </Animate>
    </div>
  );
};

export default VacancyApplicationForm;
