export const serializeFormQuery = form => {
  const formData = new FormData(form);
  const params = {};

  formData.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};
