import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { timeAgo } from '../../../../helper/dateTime';

export const ReviewCard = ({ user, rating = 0, review, date }) => {
  return (
    <div className="w-full my-1">
      <div className="bg-white border border-gray-200 rounded-lg shadow-md transition duration-300 hover:scale-102">
        <div className="p-4 flex flex-col">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex items-center mb-2 md:mb-0">
              {Array.from({ length: rating }, (_, index) => (
                <span key={index}>
                  <StarIcon style={{ color: '#ffcd00' }} />
                </span>
              ))}
              {Array.from({ length: 5 - rating }, (_, index) => (
                <span key={index}>
                  <StarBorderIcon style={{ color: '#ffcd00' }} />
                </span>
              ))}
              <span className="text-base font-semibold ml-2">
                {user?.first_name} {user?.last_name}
              </span>
            </div>
            <div className="text-sm flex items-center mb-2 md:mb-0">
              <AccessTimeIcon
                style={{
                  fontSize: '1.1rem',
                  marginBottom: '0.1rem',
                  marginRight: '4px',
                }}
              />
              {timeAgo(date)}
            </div>
          </div>
          <div>
            {review ? (
              <p className="font-thin text-sm mt-2 italic">
                <ChatOutlinedIcon
                  style={{
                    fontSize: '1.1rem',
                    marginRight: '4px',
                  }}
                />
                {'"'} {review} {'"'}{' '}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
