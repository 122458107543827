import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { toast } from 'react-toastify';
import { fetchProductReview } from '../../../services/RequestHandler/ApiRequest';
import { postReview } from '../../../services/RequestHandler/ApiPostRequest';
import Spinner from '../../components/common/Spinner';
import SubmitSpinner from '../../components/common/SubmitSpinner';
import {
  updateNotifications,
  updateNotificationReview,
} from '../../../store/slice/notification';

const ReviewAndRatings = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastDisplayedRef = useRef(false);
  const [productRestro, setProductRestro] = useState({
    products: [],
    restaurants: [],
  });
  const [loading, setLoading] = useState(true);
  const naviagate = useNavigate();
  const { handleSubmit, control } = useForm();

  const onSubmit = async data => {
    try {
      setIsSubmitting(true);
      const isSuccess = await postReview({ ...data, slug });
      if (isSuccess) {
        dispatch(updateNotificationReview({ notificationSlug: slug }));
        naviagate('/notifications');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchOrderReviewAPi = async slug => {
      dispatch(updateNotifications({ notificationSlug: slug }));
      const response = await fetchProductReview(slug);
      setLoading(false);
      if (!toastDisplayedRef.current) {
        if (response?.status !== 200) {
          toastDisplayedRef.current = true;
          toast.error(response?.message);
          naviagate('/notifications');
          return;
        }
      }
      const products = response?.data?.products;
      const restaurants = response?.data?.restaurants;
      if (response?.status === 200) {
        setProductRestro({
          products: products.map(product => ({
            ...product,
            type: 'product',
          })),
          restaurants: restaurants.map(restaurant => ({
            ...restaurant,
            type: 'restaurant',
          })),
        });
      }
    };
    fetchOrderReviewAPi(slug);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [slug]);

  const RatingComponent = forwardRef((props, ref) => (
    <ReactStars {...props} {...ref} count={5} size={20} isHalf={false} />
  ));
  const TextAreaComponent = forwardRef((props, ref) => (
    <textarea
      {...props}
      {...ref}
      className="w-full px-3 py-2 mt-2 border rounded-lg resize-y max-h-48"
    />
  ));
  const InputField = forwardRef((props, ref) => (
    <input {...props} {...ref} className="hidden" />
  ));

  const renderItems = items => {
    return items.map((item, idx) => (
      <div key={idx} className="mb-2 md:w-1/2 md:p-2">
        <h3 className="text-md ml-1">{item.name}</h3>
        <Controller
          name={`${item.type}[${idx}].rating`}
          control={control}
          defaultValue={null}
          render={({ field }) => <RatingComponent {...field} />}
        />
        <Controller
          name={`${item.type}[${idx}].comment`}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <TextAreaComponent
              {...field}
              placeholder={`Write Review on ${item?.name}`}
            />
          )}
        />
        <Controller
          name={`${item.type}[${idx}].id`}
          control={control}
          defaultValue={item?.id}
          render={({ field }) => <InputField {...field} />}
        />
      </div>
    ));
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`max-w-lg mx-auto p-6 bg-white  md:mt-5 scroll-container transform translateZ-0 ${isSubmitting ? 'opacity-50' : ''}`}
    >
      <p className="text-center my-4">
        Please rate and review our products and restaurants
      </p>
      <div className="flex items-center">
        <hr className="flex-grow border-t border-primary" />
        <span className="text-2xl mb-2 text-primary text-center font-bold px-2">
          Products
        </span>
        <hr className="flex-grow border-t border-primary" />
      </div>

      <div className="md:flex md:flex-wrap">
        {renderItems(productRestro?.products)}
      </div>

      <div className="flex items-center py-10">
        <hr className="flex-grow border-t border-primary" />
        <span className="text-2xl mb-2 text-primary text-center font-bold px-2">
          Restaurants
        </span>
        <hr className="flex-grow border-t border-primary" />
      </div>
      <div className="md:flex md:flex-wrap">
        {renderItems(productRestro?.restaurants)}
      </div>

      <div className="text-right">
        <button
          type="submit"
          className={`px-4 py-2 mt-4 font-bold text-white bg-primary rounded-full hover:bg-primary-dark ${isSubmitting ? 'opacity-50' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? <SubmitSpinner /> : null} Submit
        </button>
      </div>
    </form>
  );
};

export default ReviewAndRatings;
