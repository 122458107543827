import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postRegistration } from '../../../../services/RequestHandler/AuthRequest';
import { Helmet } from 'react-helmet-async';
import Spinner from '../../../components/common/Spinner';

function VerificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.login);
  const [authenticationChecked, setAuthenticationChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async payload => {
    setRequestSubmitted(true);
    setLoading(true);
    const response = await postRegistration(dispatch, payload);
    setLoading(false);

    if (response) {
      setRequestSubmitted(true);
      navigate('/login');
      reset();
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
    setAuthenticationChecked(true);
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (!authenticationChecked || loading) {
    return <Spinner />;
  }

  return (
    <>
      <Helmet>
        <title>Email Verification</title>
        <meta name="description" content="Signup page" />
      </Helmet>
      {requestSubmitted ? (
        <div className="bg-white py-5 px-10 rounded-md shadow-lavapaluAccent mx-auto my-8 sm:w-auto ">
          <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-4xl  text-left pb-5">
            Request Submitted
          </h1>
          <p className="text-sm">
            Your change request has been submitted. If this email address was
            used to sign up we will send an email with a link to verify it.
          </p>
          <br />
        </div>
      ) : (
        <div className="bg-white py-5 px-10 rounded-md shadow-lavapaluAccent mx-auto my-8 sm:w-auto ">
          <div>
            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 md:text-4xl  text-left pb-5">
              Request A New Email Verification Link
            </h1>
            <p className="text-sm">
              If your verification link has expired please input your email
              address you used to sign up for your account, you can use this
              form to request a new verification link.
            </p>
            <p className="text-sm">
              You must verify your email to use the applications.
            </p>

            <form
              className="mt-4 space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-row gap-4 justify-between">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Eg. example@gmail.com"
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  {errors.email && errors.email.type === 'required' && (
                    <span className="text-red-600">Email is required</span>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className=" uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default VerificationPage;
