import React, { useState, useEffect, useRef } from 'react';
import {
  postVerifyCode,
  postSendCode,
} from '../../../../services/RequestHandler/AuthRequest';
import SubmitSpinner from '../../../components/common/SubmitSpinner';
import { toast } from 'react-toastify';

const VerificationCodeInput = ({ handleCurrentPage, globalEmail }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [timerCount, setTimer] = useState(60);
  const [OTPinput, setOTPinput] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [disable, setDisable] = useState(true);
  const ref0 = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();

  const verfiyOTP = async () => {
    setIsSubmitting(true);
    try {
      if (OTPinput?.join('')?.length !== 4) {
        return toast.error('All 4 digits should be entered.');
      }
      const response = await postVerifyCode({
        code: parseInt(OTPinput.join('')),
        email: globalEmail,
      });
      if (response) {
        await handleCurrentPage(3);
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setIsSubmitting(false);
    }
  };
  const resendOTP = async () => {
    try {
      const response = await postSendCode({ email: globalEmail });
      if (response) {
        setTimer(60);
        setDisable(true);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer(lastTimerCount => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000);
    ref0.current.focus();
    return () => clearInterval(interval);
  }, [disable]);

  return (
    <div className="flex justify-center items-center h-screen sm:bg-gray-50">
      <div className="bg-white px-6 pt-10 pb-9 sm:shadow-xl mx-auto sm:rounded-2xl min-w-[25rem]">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">
              <p>Email Verification</p>
            </div>
            <div className="flex flex-col text-sm font-medium text-gray-400">
              <p>
                Please enter the code sent to email&nbsp;
                <b className="text-gray-500">{globalEmail}.</b>
              </p>
            </div>
          </div>

          <div>
            <form>
              <div className="flex flex-col space-y-16">
                <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                  <div className="w-16 h-16 ">
                    <input
                      ref={ref0}
                      maxLength="1"
                      className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                      onChange={e => {
                        setOTPinput([
                          e.target.value,
                          OTPinput[1],
                          OTPinput[2],
                          OTPinput[3],
                        ]);
                        if (e.target.value) {
                          ref1.current.focus();
                        }
                      }}
                    ></input>
                  </div>
                  <div className="w-16 h-16 ">
                    <input
                      ref={ref1}
                      maxLength="1"
                      className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                      onChange={e => {
                        setOTPinput([
                          OTPinput[0],
                          e.target.value,
                          OTPinput[2],
                          OTPinput[3],
                        ]);
                        if (e.target.value) {
                          ref2.current.focus();
                        }
                      }}
                    ></input>
                  </div>
                  <div className="w-16 h-16 ">
                    <input
                      ref={ref2}
                      maxLength="1"
                      className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                      onChange={e => {
                        setOTPinput([
                          OTPinput[0],
                          OTPinput[1],
                          e.target.value,
                          OTPinput[3],
                        ]);
                        if (e.target.value) {
                          ref3.current.focus();
                        }
                      }}
                    ></input>
                  </div>
                  <div className="w-16 h-16 ">
                    <input
                      ref={ref3}
                      maxLength="1"
                      className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      name=""
                      id=""
                      onChange={e =>
                        setOTPinput([
                          OTPinput[0],
                          OTPinput[1],
                          OTPinput[2],
                          e.target.value,
                        ])
                      }
                    ></input>
                  </div>
                </div>

                <div className="flex flex-col space-y-5">
                  <div>
                    <button
                      type="submit"
                      onClick={() => verfiyOTP()}
                      disabled={isSubmitting}
                      className={`flex flex-row ${
                        isSubmitting ? 'cursor-normal' : 'cursor-pointer'
                      } items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-blue-700 border-none text-white text-sm shadow-sm ${
                        isSubmitting ? 'bg-gray-400' : 'bg-primary'
                      }`}
                    >
                      {isSubmitting ? <SubmitSpinner /> : null}
                      <span className="py-2">Verify Account</span>
                    </button>
                  </div>

                  <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                    <p>Didn&apos;t recieve code?</p>
                    <a
                      className="flex flex-row items-center"
                      style={{
                        color: disable ? 'gray' : 'blue',
                        cursor: disable ? 'default' : 'pointer',
                      }}
                      onClick={() => resendOTP()}
                    >
                      {disable ? `Resend OTP in ${timerCount}s` : 'Resend OTP'}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerificationCodeInput;
