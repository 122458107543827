import React from 'react';
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const SocialMediaIcons = ({ className }) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      <IconButton
        color="inherit"
        href="https://www.facebook.com/"
        target="_blank"
        className="hover:text-blue-600" // Replace with your Facebook primary color
      >
        <FacebookIcon />
      </IconButton>

      <IconButton
        color="inherit"
        href="https://twitter.com/"
        target="_blank"
        className="hover:text-blue-400" // Replace with your Twitter primary color
      >
        <TwitterIcon />
      </IconButton>

      <IconButton
        color="inherit"
        href="https://www.instagram.com/"
        target="_blank"
        className="hover:text-purple-600" // Replace with your Instagram primary color
      >
        <InstagramIcon />
      </IconButton>

      <IconButton
        color="inherit"
        href="https://www.linkedin.com/"
        target="_blank"
        className="hover:text-blue-800" // Replace with your LinkedIn primary color
      >
        <LinkedInIcon />
      </IconButton>
    </div>
  );
};

export default SocialMediaIcons;
