import React from 'react';
import RecentBlog from './RecentBlog';
import Animate from '../common/Animate/Animate';

function RecentBlogs({ blogs }) {
  return (
    <div>
      {blogs.map(blog => (
        <div key={blog.id} className="py-4">
          <Animate>
            <RecentBlog blog={blog} />
          </Animate>
        </div>
      ))}
    </div>
  );
}

export default RecentBlogs;
