import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { checkPromoCodeValidation } from '../../../services/RequestHandler/ApiRequest';
import { applyPromoCodeHandler } from '../../../services/RequestHandler/ApiPostRequest';

function PromoCode({ calculateDiscountAmount, discount }) {
  const [havePromo, setHavePromo] = useState(false);
  const [validPromoCode, setValidPromoCode] = useState(false);
  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const handlePromoCodeApply = async () => {
    const promoCode = getValues('promo_code');
    if (!promoCode) {
      setError('promo_code', {
        type: 'custom',
        message: 'Enter Promo code',
      });
      return;
    }
    const res = await applyPromoCodeHandler({ promo_code: promoCode });
    if (res.status === 201) {
      if (res.data) calculateDiscountAmount(res.data);
    }
  };
  let timeOut;
  const handlePromoCodeOnChange = () => {
    clearErrors('promo_code');
    const promoCode = getValues('promo_code').toUpperCase();
    setValue('promo_code', promoCode); // Set the modified value in the form
    timeOut = setTimeout(async () => {
      if (promoCode) {
        const res = await checkPromoCodeValidation(promoCode);
        if (res.status > 300) {
          setValidPromoCode(false);
          setError('promo_code', {
            type: 'custom',
            message: res.message,
          });
        } else {
          clearErrors('promo_code');
          setValidPromoCode(true);
        }
      }
    }, 500);
    return () => clearTimeout(timeOut);
  };
  const handleHavePromo = () => setHavePromo(true);
  return (
    <div className="w-full mt-6">
      {discount ? (
        <div className="flex flex-col gap-2 mt-8 pr-6 text-sm">
          <div className="flex justify-between items-center">
            <h2>Discount:</h2>
            <h2 className="text-primary">Rs. {discount}</h2>
          </div>
        </div>
      ) : havePromo ? (
        <div className="w-full">
          <div className="rounded-lg flex gap-0 items-center overflow-hidden border border-primary">
            <div className="relative w-full">
              <input
                type="text"
                className="text-gray-900 focus:ring-none text-sm focus:border-none block w-full focus-visible:outline-none px-2 py-1.5"
                placeholder="Promo code"
                onFocus={() => clearErrors('promo_code')}
                {...register('promo_code', {
                  onChange: handlePromoCodeOnChange,
                  onBlur: handlePromoCodeOnChange,
                })}
              />
            </div>
            {validPromoCode && (
              <button
                type="button"
                className="inline-flex items-center py-1.5 px-10 h-full text-sm font-light text-black bg-primary border border-primary"
                onClick={handlePromoCodeApply}
              >
                Apply
              </button>
            )}
          </div>
          {errors.promo_code && (
            <div className="mt-1">
              <p className="text-xs text-red-600">
                {errors.promo_code.message}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <label
            className="underline text-sm text-green-600 cursor-pointer	"
            onClick={handleHavePromo}
          >
            Have promo code?
          </label>
        </div>
      )}
    </div>
  );
}

export default PromoCode;
