import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { timeAgo } from '../../../helper/dateTime';
import { MarkAsReadBtn } from '../common/Button/MuiButtons';
import { markNotification } from '../../../services/RequestHandler/ApiRequest';

function NotificationDropDown() {
  const dispatch = useDispatch();
  const { notificationList } = useSelector(state => state.notification);
  const onMark = async slug => {
    await markNotification(dispatch, slug);
  };
  return (
    <>
      <div className="text-center border-b pb-1">NOTIFICATIONS</div>
      <div className="pl-2">
        <div className="h-[50vh] menu-items overflow-y-auto">
          {notificationList?.length ? (
            <div className="mr-2">
              {notificationList?.map((item, index) => (
                <div
                  key={item?.id}
                  className={`py-2 normal-case	 rounded  gap-4 items-center flex justify-between hover:shadow-md ${
                    index ? 'border-t' : ''
                  } ${item?.seen ? 'font-extralight' : 'font-bold'}`}
                >
                  {!item?.review ? (
                    <Link to={!item?.review ? `/reviews/${item?.slug}` : '#'}>
                      <div className="">
                        <span className={` text-xs `}>{item?.order_code} </span>
                      </div>

                      <span className={`text-[0.7rem]   `}>
                        {item?.details}
                      </span>
                    </Link>
                  ) : (
                    <div>
                      <div className="cursor-default">
                        <span className={` text-xs `}>{item?.order_code} </span>
                      </div>

                      <span className={`text-[0.7rem] cursor-default`}>
                        {item?.details}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col items-end">
                    <span className="mt-[-5px]">
                      {item?.seen ? null : (
                        <MarkAsReadBtn onMark={() => onMark(item?.slug)} />
                      )}
                    </span>
                    <span className="text-[0.6rem] ">
                      {timeAgo(item?.updated_at)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-xs mt-4 text-center">
              No new Notifications Available !
            </div>
          )}
        </div>
      </div>
      <Link to="/notifications">
        <div className="text-center my-1 pt-1 border-t">Expand</div>
      </Link>
    </>
  );
}

export default NotificationDropDown;
