import React from 'react';
import { useSelector } from 'react-redux';
import Banners from '.';

function BannerOne() {
  const { banner_ones: banners } = useSelector(state => state.homePage.banners);
  if (!banners || banners.length <= 0) return null;

  return <Banners banners={banners} bannerNumber={1} />;
}

export default BannerOne;
