import { useState, useEffect, useRef } from 'react';

function isClickable(element) {
  const clickableTagNames = ['BUTTON', 'INPUT', 'LABEL', 'SELECT', 'A'];
  if (clickableTagNames.includes(element.tagName)) {
    return true;
  }
  // Check if any ancestor of the element is clickable
  const ancestor = element.closest(clickableTagNames.join(', '));
  return !!ancestor;
}
function DropDownMenu({
  Menu,
  children,
  width,
  orientation = 'center',
  showMenuOnClick = false,
  ...props
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && menuRef.current.contains(event.target)) {
        if (isClickable(event.target)) {
          setShowMenu(false);
        }
      } else if (btnRef.current && !btnRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const hideMenu = () => setShowMenu(false);
  const showMenuOnScreen = () => setShowMenu(true);
  const toggleMenu = () => setShowMenu(!showMenu);
  const getPropsForBtn = () => {
    if (showMenuOnClick) {
      return { onClick: toggleMenu };
    } else {
      return { onMouseEnter: showMenuOnScreen, onMouseLeave: hideMenu };
    }
  };
  const getProps = () => {
    if (!showMenuOnClick) {
      return { onMouseEnter: showMenuOnScreen, onMouseLeave: hideMenu };
    }
  };
  const getOrientation = () => {
    switch (orientation) {
      case 'center':
        return '-translate-x-1/2';
      case 'left':
        return '-translate-x-2/3';
      case 'full-left':
        return '-translate-x-full';
      case 'right':
        return '-translate-x-1/3';
      case 'full-right':
        return '';
      default:
        return '-translate-x-1/2';
    }
  };

  return (
    <div className="relative inline-block text-left">
      <button {...getPropsForBtn()} {...props} ref={btnRef}>
        {children}
      </button>

      <div
        ref={menuRef}
        {...getProps()}
        className={`${
          showMenu ? 'absolute' : 'hidden'
        } left-[-50px] transform ${getOrientation()} z-1 bg-transparent pt-3 origin-top-left  `}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        style={{
          width: width || 250,
        }}
        tabIndex="-1"
      >
        {Menu && (
          <div className="py-1 bg-white rounded-md shadow-lg ring-1 border shadow-dark ring-black ring-opacity-5 focus:outline-none">
            {Menu}
          </div>
        )}
      </div>
    </div>
  );
}

export default DropDownMenu;
