import { toast } from 'react-toastify';
import Api from '../Api';
import { addItem, removeItem, clearCart } from '../../store/slice/cart';
import { addFavorite, removeFavorite } from '../../store/slice/favoriteItems';
import { updateUser } from '../../store/slice/login';

export const postData = async (url, body) => {
  try {
    const res = await Api.post(url, body);
    return res?.data;
  } catch (error) {
    console.error('Error occured while posting data', error);
  }
};

export const postCart = async (dispatch, payload) => {
  try {
    const { product_code, unit, remove = 'no', hideToast } = payload;
    if (!product_code) {
      return toast.error('Product code is not available');
    }
    const body = {
      product_code,
      unit,
      remove,
    };

    const response = await postData('/carts', body);
    if (response?.status === 201) {
      if (remove == 'no') {
        dispatch(addItem(response?.data));
        if (!hideToast) {
          toast.success(`${response?.data?.product?.name} added to cart`);
        }
      } else {
        dispatch(removeItem({ itemId: response?.data?.product?.id }));
        if (!hideToast) {
          toast.warning(`${response?.data?.product?.name} decreased from cart`);
        }
      }
      return response;
    } else {
      toast.error(
        response?.message ||
          'Something went wrong while adding product to cart',
      );
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const postCheckout = async (dispatch, payload) => {
  try {
    const { checkoutCart, deliveryOption, paymentMethod, ...deliveryDetails } =
      payload;
    const body = {
      ...deliveryDetails,
      checkoutCart,
      deliveryOption,
      payment_method: paymentMethod || deliveryDetails?.payment_method,
    };

    const response = await postData('/checkout', body);
    if (response?.status === 201) {
      dispatch(clearCart());
      toast.success(`Checkout is success`);
      return response;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const postFavourite = async (dispatch, payload) => {
  try {
    const { product_code, name, id, removeFavourite } = payload;
    const body = {
      product_code,
      removeFavourite,
    };

    const response = await postData('/favourites', body);
    if (response?.status === 201) {
      dispatch(addFavorite(response?.data));
      toast.success(`${response?.data?.product?.name} added to favourites`);
      return;
    } else if (response?.status === 204) {
      dispatch(removeFavorite({ itemId: id }));
      toast.warning(`${name} removed from favourites`);
      return;
    } else if (response?.status === 409) {
      toast.warn(`${name} already added to favourites`);
    } else {
      toast.error('Something went wrong in favourites');
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const updateUserProfile = async (dispatch, payload) => {
  try {
    const res = await Api.post('/user/profile', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res.status < 300) {
      if (res?.data?.data) dispatch(updateUser({ user: res.data.data }));
      toast.success('Profile updated successfully');
    }
  } catch (error) {
    toast.error(error.message);
    console.log('error', error);
  }
};

export const setDefaultAddress = async (dispatch, payload) => {
  try {
    const res = await Api.post('/user/address', payload);
    if (res.status < 300) {
      if (res?.data?.data) dispatch(updateUser({ user: res.data.data.user }));
      toast.success('Address added successfully');
    }
  } catch (error) {
    toast.error(error.message);
    console.log('error', error);
  }
};

export const postVacancyApplication = async (slug, payload) => {
  try {
    const res = await Api.post(`/career/vacancies/${slug}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.status == 201) {
      toast.success('Application submitted successfully');
      return true;
    }
    toast.error(res?.data?.message || 'Something went wrong');
    return false;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
};

export const postReview = async payload => {
  try {
    const body = {
      ...payload,
    };
    const response = await postData('/reviews', body);
    if (response?.status === 201) {
      toast.success(`Review saved successfully`);
      return true;
    } else {
      toast.error(response?.message);
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};
export const applyPromoCodeHandler = async payload => {
  try {
    const body = {
      ...payload,
    };
    const response = await postData('apply-promo-code', body);
    if (response?.status < 300) {
      toast.success(`Promo Code applied successfully`);
      return response;
    } else {
      toast.error(response?.message);
      return response;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
    return false;
  }
};
