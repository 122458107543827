import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import './style.css';
import CartDropDown from '../Modal/CartDropDown';
import NotificationDropDown from '../Modal/NotificationDropDown';
import DropDownMenu from '../common/Dropdown';
import { TabsItems } from '../../pages/Profile/Tab';
import UserIcon from '../../../assets/svg/user-icon.svg';
import { getMe } from '../../../services/RequestHandler/AuthRequest';

const navItems = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'About',
    to: '/about',
  },
  {
    title: 'Blog',
    to: '/blog',
  },
  {
    title: 'Career',
    to: '/career',
  },
];

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileNav, setMobileNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { totalItems: totalCartItems } = useSelector(store => store.cart);
  const { unseenNotifications } = useSelector(store => store.notification);
  const scrollThreshold = 500; // Adjust this value to your desired threshold

  const showFixedNav = () => scrollPosition > scrollThreshold;
  const hideMobileNav = () => setMobileNav(false);

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  };
  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Adds smooth scrolling animation
    });
  };
  const handleProfileClick = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('urlChanged'));
    }, 100);
  };
  const { isAuthenticated, user } = useSelector(state => state.login);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      if (!getMe(dispatch)) {
        navigate('/login');
      }
    }
  }, []);
  const profileMenu = (
    <div className="capitalize px-2 z-11 ">
      <div className="flex justify-center py-2">
        <div className="h-16 w-16 rounded-full text-black border flex justify-center items-center">
          {user?.image_url ? (
            <img src={user.image_url} alt="user" className="rounded-full"></img>
          ) : (
            <UserIcon />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-1 items-center my-2">
        <p>
          {user?.first_name} {user?.last_name}
        </p>
        <NavLink
          to="/profile"
          onClick={handleProfileClick}
          className="text-green-500 hover:text-green-700"
        >
          Edit profile
        </NavLink>
      </div>
      <hr />
      <div className="py-2">
        {TabsItems.map(item => (
          <NavLink
            key={item.id}
            className={` text-sm block py-1 md:px-2 hover:cursor-pointer hover:border-primary hover:text-primary transition-all`}
            tabIndex={0}
            to={`/profile?tabs=${item.title.toLowerCase().replace(' ', '-')}`}
            onClick={handleProfileClick}
          >
            <p className="py-0.5">
              <span>{item.icon}</span> {item.title}
            </p>
          </NavLink>
        ))}
      </div>
      <hr />
      <div className="py-1">
        <NavLink
          to="/logout"
          className="text-sm block text-center py-1 text-green-500 hover:text-green-700"
        >
          Logout
        </NavLink>
      </div>
    </div>
  );

  return (
    <>
      <nav
        className={`uppercase fixed fixed-nav w-full left-0 top-0 show text-sm text-gray-900 py-2.5 bg-white lg:bg-white shadow`}
      >
        <div className="hidden lg:flex justify-end items-center responsive-width">
          <div className="flex justify-end items-center gap-2">
            <ul className="flex justify-between gap-12 items-center	">
              {navItems.map(item => (
                <div key={item.title}>
                  <NavLink to={item.to}>
                    <li>{item.title}</li>
                  </NavLink>
                </div>
              ))}
              {isAuthenticated ? (
                <div className="flex flex-row gap-2">
                  <DropDownMenu
                    orientation="left"
                    width={300}
                    Menu={<NotificationDropDown />}
                    showMenuOnClick={true}
                  >
                    <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:bg-[#dedede] p-2 text-sm font-medium text-center bg-[#f2f2f2]">
                      <NotificationsIcon fontSize="14px" />
                      {unseenNotifications > 0 ? (
                        <div className="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold text-white bg-primary border-2 border-primary rounded-full top-0 -right-2">
                          {unseenNotifications}
                        </div>
                      ) : null}
                    </div>
                  </DropDownMenu>
                  <DropDownMenu
                    orientation="left"
                    width={300}
                    Menu={<CartDropDown />}
                    showMenuOnClick={true}
                  >
                    <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:bg-[#dedede] p-2 text-sm font-medium text-center bg-[#f2f2f2]">
                      <ShoppingCartIcon fontSize="14px" />
                      {totalCartItems > 0 ? (
                        <div className="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold text-white bg-primary border-2 border-primary rounded-full top-0 -right-2">
                          {totalCartItems}
                        </div>
                      ) : null}
                    </div>
                  </DropDownMenu>
                  <DropDownMenu Menu={profileMenu} showMenuOnClick={true}>
                    <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:opacity-60">
                      {user?.image_url ? (
                        <img
                          src={user.image_url}
                          alt="user"
                          className="rounded-full"
                        ></img>
                      ) : (
                        <UserIcon />
                      )}
                    </div>
                  </DropDownMenu>
                </div>
              ) : (
                <NavLink to="/login">Login</NavLink>
              )}
            </ul>
            <div className="flex justify-between items-center">
              <span className="h-8 "></span>
            </div>
          </div>
        </div>
        <div className="lg:hidden text-gray-900 px-[8%]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
              <button
                className={`show-mobile-menu text-gray-500 hidden ${
                  mobileNav ? 'active' : ''
                }  z-50`}
                onClick={() => setMobileNav(!mobileNav)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <NavLink to="/" className="text-lg">
                Menu
              </NavLink>
            </div>

            {!isAuthenticated ? (
              <div>
                <NavLink to="/login">Login</NavLink>
              </div>
            ) : (
              <div className="flex  flex-row gap-2">
                <DropDownMenu
                  orientation="left"
                  width={300}
                  Menu={<NotificationDropDown />}
                  showMenuOnClick={true}
                >
                  <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:bg-[#dedede] p-2 text-sm font-medium text-center bg-[#f2f2f2]">
                    <NotificationsIcon fontSize="14px" />
                    {unseenNotifications > 0 ? (
                      <div className="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold text-white bg-primary border-2 border-primary rounded-full top-0 -right-2">
                        {unseenNotifications}
                      </div>
                    ) : null}
                  </div>
                </DropDownMenu>
                <DropDownMenu
                  orientation="left"
                  width={300}
                  Menu={<CartDropDown />}
                  showMenuOnClick={true}
                >
                  <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:bg-[#dedede] p-2 text-sm font-medium text-center bg-[#f2f2f2]">
                    <ShoppingCartIcon fontSize="14px" />
                    {totalCartItems > 0 ? (
                      <div className="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold text-white bg-primary border-2 border-primary rounded-full top-0 -right-2">
                        {totalCartItems}
                      </div>
                    ) : null}
                  </div>
                </DropDownMenu>
                <DropDownMenu Menu={profileMenu} showMenuOnClick={true}>
                  <div className="border rounded-full h-8 w-8 overflow-hidden flex justify-center items-center hover:opacity-60">
                    {user?.image_url ? (
                      <img
                        src={user.image_url}
                        alt="user"
                        className="rounded-full"
                      ></img>
                    ) : (
                      <UserIcon />
                    )}
                  </div>
                </DropDownMenu>
              </div>
            )}
          </div>

          <div
            className={`mobile-nav overflow-hidden ${mobileNav ? 'show' : ''}`}
          >
            <ul className="flex flex-col justify-between gap-5 pt-6">
              {navItems.map(item => (
                <li key={item.title}>
                  <NavLink onClick={hideMobileNav} to={item.to}>
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <button
        className={`${
          showFixedNav() ? 'fixed' : 'hidden'
        } transition duration-1000 right-3 bottom-5 z-50`}
        onClick={scrollToTop}
      >
        <div className="bg-primary opacity-70 animate-bounce rounded-full w-10 h-10 flex items-center justify-center">
          <div className="text-white">
            <KeyboardArrowUpIcon fontSize="large" />
          </div>
        </div>
      </button>
    </>
  );
}
