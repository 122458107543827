import React from 'react';

function AddressDetails({ fullName, phone, address, addressDescription }) {
  return (
    <div className="text-xs">
      <div className="flex flex-col gap-2 items-start justify-normal">
        <p>{fullName},</p>
        {phone && <p>{phone},</p>}
        {address && <p className="inline-block text-start">{address},</p>}
        {addressDescription && (
          <p className="inline-block text-start">{addressDescription}</p>
        )}
      </div>
    </div>
  );
}

export default AddressDetails;
