import IconButton from '@mui/material/IconButton';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
// import Tooltip from '@mui/material/Tooltip';

export const MarkAsReadBtn = ({ onMark }) => {
  const onClickButton = () => {
    if (onMark) {
      onMark();
    }
  };
  return (
    // <Tooltip title="Mark as Read" arrow>
    <IconButton
      style={{
        borderRadius: '50%',
        backgroundColor: '#ecececdb',
        padding: '0.3rem',
      }}
      onClick={onClickButton}
    >
      <MarkEmailReadOutlinedIcon style={{ fontSize: '1rem', color: 'black' }} />
    </IconButton>
    // </Tooltip>
  );
};
