import Navbar from '../Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchHomePageBanner,
  fetchHomePageData,
} from '../../../services/RequestHandler/ApiRequest';
import {
  setGoogleMapApiLoaded,
  setGoogleMapApiNotLoaded,
} from '../../../store/slice/GoogleMapApi';

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export default function Layout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHomePageData());
    dispatch(fetchHomePageBanner());
  }, [dispatch]);
  const { isGoogleMapApiLoaded } = useSelector(state => state.googleMap);

  useEffect(() => {
    let script;
    // Load the Google Maps API if it hasn't been loaded yet
    if (!isGoogleMapApiLoaded) {
      script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=marker`;
      script.onload = () => dispatch(setGoogleMapApiLoaded());
      document.head.appendChild(script);
    }
    // Cleanup function
    return () => {
      if (script) {
        document.head.removeChild(script);
        dispatch(setGoogleMapApiNotLoaded());
      }
    };
  }, []);
  return (
    <>
      <div className="navbar h-[50px]">
        <Navbar />
      </div>
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
