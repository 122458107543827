import React, { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import UserIcon from '../../../assets/svg/user-icon.svg';
import ImageUploader from '../../components/common/ImageUploader/ImageUploader';
import DropDownMenu from '../common/Dropdown';
import { updateUserProfile } from '../../../services/RequestHandler/ApiPostRequest';
import Spinner from '../common/Spinner';

const requiredStar = <span className="text-red-600">*</span>;

function MyProfile() {
  const imageInputRef = useRef(null);
  const { user } = useSelector(state => state.login);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  if (!user) return null;
  const formDefaultValue = {
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
    email: user.email,
    image: user.image_url,
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: formDefaultValue,
  });
  const [imageSrc, setImageSrc] = useState(user.image_url);
  const handleImageInputChange = image => {
    // Set a specific file value (for example, "someValue")
    setImageSrc(image);
    setValue('image', image);
  };

  const onSubmit = async payload => {
    setIsLoading(true);
    await updateUserProfile(dispatch, payload);
    reset({ ...formDefaultValue });
    setIsLoading(false);
  };
  const handleCancelClick = () => {
    reset({ ...formDefaultValue });
  };

  const handleImageInputClick = () => {
    imageInputRef.current.click();
  };
  const menu = (
    <div className="px-1 text-sm flex flex-col">
      <button
        onClick={handleImageInputClick}
        className="px-2 py-2 rounded w-full text-left hover:bg-primary hover:bg-opacity-20"
      >
        Upload image
      </button>
      <a
        href="/profile"
        target="_blank"
        className="px-2 py-2 rounded w-full text-left hover:bg-primary hover:bg-opacity-20"
      >
        View Profile image
      </a>
    </div>
  );
  return (
    <div>
      {isLoading && <Spinner />}
      <h3 className="uppercase text-sm font-bold text-gray-500 tracking-tighter md:pt-0 pt-4">
        Profile picture
      </h3>
      {/* <button
        onClick={handleImageInputClick}
        className="h-32 w-32 my-8 rounded-full text-black border flex justify-center items-center"
      >
        <UserIcon />
      </button> */}
      <div className="my-8">
        <DropDownMenu
          showMenuOnClick={true}
          orientation="center"
          Menu={menu}
          className="h-28 w-28 rounded-full text-black border flex justify-center items-center"
        >
          {user?.image_url ? (
            <img src={imageSrc} alt="user" className="rounded-full" />
          ) : (
            <UserIcon />
          )}
        </DropDownMenu>
      </div>
      <ImageUploader
        innerRef={imageInputRef}
        className="hidden"
        onImageChange={handleImageInputChange}
      />
      <div className="form-section my-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="image  "
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <input type="file" style={{ display: 'none' }} {...field} />
            )}
          />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="flex flex-col gap-1.5">
              <label className="uppercase text-sm font-bold text-gray-500 tracking-tighter">
                First Name {requiredStar}
              </label>
              <input
                className={`border ${
                  errors.firstName ? 'border-red-300' : 'border-gray-300'
                } focus:border-primary w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                type="text"
                placeholder="First Name"
                {...register('firstName', {
                  required: 'This fields is required',
                })}
              />
              {errors.firstName && (
                <p className="text-sm text-red-600">
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="uppercase text-sm font-bold text-gray-500 tracking-tighter">
                Last Name {requiredStar}
              </label>
              <input
                className={`border ${
                  errors.lastName ? 'border-red-300' : 'border-gray-300'
                } focus:border-primary w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                type="text"
                placeholder="Last Name"
                {...register('lastName', {
                  required: 'This fields is required',
                })}
              />
              {errors.lastName && (
                <p className="text-sm text-red-600">
                  {errors.lastName.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="uppercase text-sm font-bold text-gray-500 tracking-tighter">
                Phone Number {requiredStar}
              </label>
              <input
                className={`border ${
                  errors.phone ? 'border-red-300' : 'border-gray-300'
                } focus:border-primary w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                type="text"
                placeholder="Phone Number"
                {...register('phone', {
                  required: 'This fields is required',
                })}
              />
              {errors.phone && (
                <p className="text-sm text-red-600">{errors.phone.message}</p>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="uppercase text-sm font-bold text-gray-500 tracking-tighter">
                Email {requiredStar}
              </label>
              <input
                className={`border ${
                  errors.email ? 'border-red-300' : 'border-gray-300'
                } focus:border-primary w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: 'This fields is required',
                })}
              />
              {errors.email && (
                <p className="text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>
          </div>
          <div className="flex gap-6 my-6">
            <button
              disabled={!isDirty}
              type="submit"
              className="bg-primary hover:bg-[#E1A057] border border-primary rounded px-8 py-1 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleCancelClick}
              className="bg-gray-500 hover:bg-gray-600 border border-gray-400 text-white rounded px-8 py-1"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyProfile;
