import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Animate from '../common/Animate/Animate';
import {
  postCart,
  postFavourite,
} from '../../../services/RequestHandler/ApiPostRequest';
import useAuthenticatedAction from '../../../utils/auth';

function ItemCard({ item = {} }) {
  const [hovered, setHovered] = useState(false);
  const [assigningFav, setAssigningFav] = useState(false);
  const [addingCart, setAddingCart] = useState(false);
  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  const { handleAuthenticatedAction } = useAuthenticatedAction();
  const { favoriteItems } = useSelector(state => state.favorite);
  const productInFavourite = !!favoriteItems?.find(
    favProduct => favProduct?.id === item?.id,
  );

  const handleAddToCart = async payload => {
    setAddingCart(true);
    await handleAuthenticatedAction(postCart, { ...payload, unit: 1 });
    setAddingCart(false);
  };
  const handleFavoriteClick = async payload => {
    setAssigningFav(true);
    await handleAuthenticatedAction(postFavourite, {
      ...payload,
      removeFavourite: false,
    });
    setAssigningFav(false);
  };
  return (
    <Animate>
      <div className="min-w-[200px] w-full max-w-[500px] aspect-3/2 px-3 z-0">
        <div
          className="bg-white border border-gray-200 hover:shadow-lg"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="overflow-hidden">
            <div to={`/${item.slug}`}>
              {item.image_url ? (
                <img
                  src={item.image_url}
                  alt={item.name}
                  className={`${
                    hovered ? 'scale-105' : ''
                  } object-cover transition duration-500`}
                />
              ) : (
                <div
                  className={`${
                    hovered ? 'scale-105' : ''
                  } bg-secondary h-full flex items-center justify-center transition duration-500`}
                >
                  <h1 className="text-2xl text-gray-500">Item</h1>
                </div>
              )}
            </div>
          </div>
          <div className="px-4 py-2 flex flex-col">
            <p className="text-black text-sm font-roboto font-light text-lg leading-7 tracking-tight align-baseline tracking-wider whitespace-nowrap	overflow-hidden w-full text-ellipsis">
              {item.name}
            </p>

            {hovered ? (
              <div className="flex gap-2 justify-center items-center">
                <button
                  onClick={() => handleAddToCart(item)}
                  disabled={addingCart}
                >
                  <div
                    className={`rounded-full flex items-center justify-center text-primary hover:text-white border border-primary hover:bg-primary w-9 h-9 ${addingCart ? 'opacity-50 cursor-wait' : ' '}`}
                  >
                    <div className="">
                      <ShoppingCartIcon fontSize="sm" />
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => handleFavoriteClick(item)}
                  disabled={assigningFav}
                  style={assigningFav ? { cursor: 'wait' } : null}
                >
                  <div
                    className={`rounded-full flex items-center justify-center border border-primary text-primary hover:text-white hover:bg-primary ${
                      productInFavourite ? 'bg-primary text-white' : ''
                    } ${assigningFav ? 'opacity-50' : ' '} w-9 h-9`}
                  >
                    <div>
                      <FavoriteIcon fontSize="sm" />
                    </div>
                  </div>
                </button>
              </div>
            ) : (
              <p className="flex gap-1 items-center  py-2 text-sm font-medium text-primary text-center">
                <span>Rs.</span>
                {item.discount_status ? (
                  <>
                    <span className="line-through	text-gray-400">
                      {item.amount}
                    </span>
                    <span>{item?.price_final}</span>
                  </>
                ) : (
                  item.price_final
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </Animate>
  );
}
export default ItemCard;
