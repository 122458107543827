import React, { useState } from 'react';

function ServiceIconTitle({ title, subtitle, icon, bgColor }) {
  const [hover, setHover] = useState(false);
  const hovered = () => setHover(true);
  const hoverGone = () => setHover(false);
  const hoverBg = 'bg-[#EF8F1E]';
  return (
    <div
      className="flex flex-col gap-2 items-center"
      onMouseEnter={hovered}
      onMouseLeave={hoverGone}
    >
      <div
        className={`p-3 transition duration-700 ease-in-out ${
          hover ? hoverBg : bgColor
        } rounded-full`}
      >
        <div className="icon border border-gray-200 rounded-full p-3">
          {icon}
        </div>
      </div>
      <h3 className="font-bold uppercase text-sm font-roboto">{title}</h3>
      <p className="text-xs uppercase font-bold opacity-50 tracking-wider	">
        {subtitle}
      </p>
    </div>
  );
}

export default ServiceIconTitle;
