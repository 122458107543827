/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  ordersList: [],
  loading: true,
  loadMoreOrders: true,
};
const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    orders: (state, action) => {
      const newOrders = action.payload.orders || [];
      let uniqueOrders;
      if (action.payload?.page === 1) {
        state.ordersList = newOrders;
      } else {
        const concatenatedArray = newOrders.concat(state.ordersList);

        const uniqueObjectsSet = new Set(
          concatenatedArray.map(objArray => objArray[0]?.order_code),
        );

        uniqueOrders = Array.from(uniqueObjectsSet).map(orderCode => {
          return concatenatedArray.find(
            objArray => objArray[0]?.order_code === orderCode,
          );
        });

        uniqueOrders?.sort(
          (a, b) => new Date(b[0]?.created_at) - new Date(a[0]?.created_at),
        );

        state.ordersList = [...uniqueOrders];
      }

      if (
        state.ordersList?.length >= action.payload?.total_results ||
        action.payload?.page === action.payload?.total_pages
      ) {
        state.loadMoreOrders = false;
      }
      state.loading = false;
    },
    startOrdersLoading: state => {
      state.loading = true;
    },
    stopOrdersLoading: state => {
      state.loading = false;
    },
    stopLoadMoreOrders: state => {
      state.loadMoreOrders = false;
    },
  },
});
export const {
  orders,
  startOrdersLoading,
  stopOrdersLoading,
  stopLoadMoreOrders,
} = ordersSlice.actions;
export default ordersSlice.reducer;
