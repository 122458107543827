import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubmitSpinner from '../../components/common/SubmitSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { postCart } from '../../../services/RequestHandler/ApiPostRequest';
import { removeCart } from '../../../services/RequestHandler/ApiDeleteRequest';
import './Accordion.css';
import PromoCode from '../PromoCode/PromoCode';

function CartDetail() {
  const { items } = useSelector(state => state.cart);
  const { branches } = useSelector(state => state.homePage);
  const [cartItems, setCartItems] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [isClick, setIsClick] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [notExpandedItems, setNotExpandedItems] = useState({});
  const dispatch = useDispatch();
  const getBranchNameById = id => {
    if (branches) return branches.find(branch => branch.id == id)?.name;
    else return;
  };
  const getTotalCartCost = branchHash => {
    let totalCost = 0;
    Object.keys(branchHash).forEach(key => {
      totalCost += branchHash[key].cost;
    });
    return totalCost;
  };
  const calculateDiscountAmount = promo => {
    if (promo) {
      if (promo.type === 'percent') {
        setDiscount(
          () => (totalCost * parseFloat(promo.discount)) / 100,
        ).toFixed(2);
      } else {
        setDiscount(() => parseFloat(promo.discount));
      }
    }
  };

  // Function to toggle the expanded state of an accordion item
  const toggleExpanded = key => {
    setNotExpandedItems(prevState => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the state of the specific key
    }));
  };

  const handleCarItemAllRemove = async payload => {
    setIsClick(payload?.id);
    await removeCart(dispatch, { ...payload });
    setIsClick(null);
  };

  const handleCarItemAdd = async payload => {
    setIsClick(payload?.id);

    await postCart(dispatch, { ...payload, unit: 1, hideToast: true });
    setIsClick(null);
  };
  const handleCarItemRemove = async payload => {
    setIsClick(payload?.id);
    await postCart(dispatch, {
      ...payload,
      unit: 1,
      remove: 'yes',
      hideToast: true,
    });
    setIsClick(null);
  };
  useEffect(() => {
    const branchHash = {};
    items.forEach(item => {
      const cost =
        item.quantity *
        (item.discount_status
          ? parseFloat(item.amount) - parseFloat(item.discount)
          : item.amount);
      if (item.branch_id in branchHash) {
        branchHash[item.branch_id].items.push(item);
        branchHash[item.branch_id].cost += cost;
      } else {
        branchHash[item.branch_id] = { items: [item] };
        branchHash[item.branch_id].cost = cost;
      }
    });
    setCartItems({ ...branchHash });
    setTotalCost(() => getTotalCartCost(branchHash));
  }, [items]);
  return (
    <div className="py-3">
      {Object.keys(cartItems).map((key, index) => (
        <div key={key} className="my-2.5">
          <Accordion
            expanded={!notExpandedItems[index]}
            onChange={() => toggleExpanded(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="w-full flex justify-between items-center border-b">
                <p className="py-0.5 text-sm text-green-500 ">
                  {getBranchNameById(key) || `Branch id ${key}`}
                </p>
                <p className="text-sm text-primary">
                  Rs. {cartItems[key].cost}
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="pr-4">
                {cartItems[key]?.items?.map((item, index) => (
                  <div
                    className={`py-2 px-2 text-xs flex flex-col gap-2 ${
                      index ? 'border-t' : ''
                    }`}
                    key={item.id}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex basis-2/5 gap-4  ">
                        <p className=" text-base">
                          {item.name.slice(0, 20)}
                          {item.name.length > 20 ? '...' : ''}
                        </p>
                      </div>
                      <div className="basis-1/3 flex gap-2 justify-start">
                        <button
                          onClick={() => handleCarItemAdd(item)}
                          type="button"
                          className="h-5 w-6 flex justify-center items-center border border-green-400 font-bold text-lg text-green-600"
                        >
                          +
                        </button>
                        <button
                          onClick={() => handleCarItemRemove(item)}
                          type="button"
                          className="h-5 w-6 flex justify-center items-center border border-green-400 font-bold text-2xl text-green-600"
                        >
                          -
                        </button>
                        <button
                          onClick={() => handleCarItemAllRemove(item)}
                          type="button"
                          className="h-5 w-6 flex justify-center items-center  text-gray-400 hover:text-red-500 transition duration-200 ease-in"
                        >
                          <DeleteIcon sx={{ color: 'red' }} />
                        </button>
                      </div>

                      <div className="flex flex-row w-20 justify-between ">
                        {isClick === item?.id ? (
                          <SubmitSpinner />
                        ) : (
                          <span className="bg-gray-200 h-5 w-5 flex  justify-center items-center rounded-full">
                            {item?.quantity}
                          </span>
                        )}

                        <span className="text-primary basis-1/3 text-right text-sm">
                          Rs.
                          {item.quantity *
                            (item.discount_status
                              ? parseFloat(item.amount) -
                                parseFloat(item.discount)
                              : item.amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      <div>
        <PromoCode
          discount={discount}
          calculateDiscountAmount={calculateDiscountAmount}
        />
      </div>

      {items.length > 0 && (
        <div className="flex flex-col gap-2 mt-8 pr-6 text-sm">
          <div className="flex justify-between items-center">
            <h2>Total Cost:</h2>
            <h2 className="text-primary">Rs. {totalCost - discount}</h2>
          </div>
        </div>
      )}
    </div>
  );
}

export default CartDetail;
