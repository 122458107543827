import React from 'react';
import NewsLetter from '../common/NewsLetter';
import FooterContent from './FooterContent';

function Footer() {
  return (
    <footer className="footer-section">
      <NewsLetter />
      <FooterContent />
    </footer>
  );
}

export default Footer;
