import React from 'react';
import { useSelector } from 'react-redux';
import Banners from '.';

function BannerThree() {
  const { banner_threes: banners } = useSelector(
    state => state.homePage.banners,
  );
  if (banners?.length <= 0) return null;
  return <Banners banners={banners} bannerNumber={3} />;
}

export default BannerThree;
