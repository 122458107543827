/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productCategoriesList: [],
  notFound: false,
  branches: [],
};

const productCategoriesListSlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    storeProductCategoriesList: (state, action) => {
      state.productCategoriesList = action?.payload?.data || [];
      state.notFound = action.payload.status !== 200;
    },
    storeBranchList: (state, action) => {
      if (
        !state?.branches?.find(
          branchObj =>
            branchObj?.branch?.slug === action?.payload?.data?.branch?.slug,
        )
      ) {
        state.branches = [...state.branches, { ...action?.payload?.data }];
      }
    },
  },
});

export const { storeProductCategoriesList, storeBranchList } =
  productCategoriesListSlice.actions;
export default productCategoriesListSlice.reducer;
