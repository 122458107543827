import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../common/Modal';
import AddressFormModal from '../Modal/AddressFormModal';
import AddressDetails from './AddressDetails';
import { setDefaultAddress } from '../../../services/RequestHandler/ApiPostRequest';

function DeliveryDetail() {
  const { user } = useSelector(state => state.login);
  const dispatch = useDispatch();
  const [isTempAddress, setIsTempAddress] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [isDefaultAddressSelected, setIsDefaultAddressSelected] = useState(
    user.address ? true : false,
  );
  const [tempAddress, setTempAddress] = useState({});
  const hideAddressModal = () => setAddressModal(false);
  const showAddressModal = () => setAddressModal(true);
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const handleMapForm = async data => {
    if (data) {
      const tempAddr = {
        address: data.address,
        addressDescription: data.addressDescription,
        geoLocation: data.geoLocation,
      };
      setTempAddress(tempAddr);
      setIsTempAddress(true);
      if (!user.address?.address) {
        await setDefaultAddress(dispatch, tempAddr);
        setIsTempAddress(false);
        setTempAddress({});
      }
    }
  };
  const setDefaultAddressInput = () => {
    setValue('address', user.address?.address);
    setValue('addressDescription', user.address?.addressDescription);
    setValue('isDefaultAddress', true);
    setIsDefaultAddressSelected(true);
  };
  const removeDefaultAddressInput = () => {
    setValue('address', tempAddress.address);
    setValue('addressDescription', tempAddress.addressDescription);
    setValue('geoLocation', tempAddress.geoLocation);
    setValue('isDefaultAddress', false);
    setIsDefaultAddressSelected(false);
  };

  useEffect(() => {
    setValue(
      'fullName',
      `${user.first_name ? user.first_name : ''} ${
        user.last_name ? user.last_name : ''
      }`,
    );
    if (user.phone) setValue('phoneNumber', user.phone);
    if (user.address?.address) {
      setValue('address', user.address?.address);
      setValue('addressDescription', user.address?.addressDescription);
      setValue('isDefaultAddress', true);
      setIsDefaultAddressSelected(true);
    }
  }, [user]);
  return (
    <div className="border p-4">
      <div className="flex flex-col gap-4 pb-4">
        <h1 className="text-2xl">Billing Details</h1>
        <div className="fex flex-col gap-2 text-sm py-1">
          <h3 className="mb-2">{`${user.first_name} ${user.last_name}`}</h3>
          <input
            type="hidden"
            {...register('fullName', { required: 'This field is required' })}
          />
          {user.phone ? (
            <h3>{user.phone}</h3>
          ) : (
            <h1 className="text-xl mb-2 text-black opacity-100 mt-4">
              Phone Number <span className="text-red-600">*</span>
            </h1>
          )}
          <input
            className="py-2.5 w-full px-4 border border-gray-400"
            type={user.phone ? 'hidden' : `text`}
            placeholder="Enter you phone number"
            {...register('phoneNumber', { required: 'This field is required' })}
          />
          {errors.phoneNumber && (
            <p className="text-xs text-red-600 mt-2">
              {errors.phoneNumber.message}
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="py-2">
        <h1 className="text-2xl mb-2">
          Delivery Details <span className="text-red-600">*</span>
        </h1>
        <div className="my-2 ml-4 flex flex-row gap-10  items-stretch">
          {user.address?.address && (
            <button
              type="button"
              onClick={setDefaultAddressInput}
              className={`border rounded ${
                isDefaultAddressSelected
                  ? 'border-green-600'
                  : 'border-transparent'
              } max-w-54 min-h-[80px] p-2 hover:cursor-pointer text-xs bg-gray-200 rounded`}
            >
              <AddressDetails
                fullName={`${user.first_name ? user.first_name : ''} ${
                  user.last_name ? user.last_name : ''
                }`}
                phone={user.phone}
                address={user.address?.address}
                addressDescription={user.address?.addressDescription}
              />
            </button>
          )}
          {isTempAddress && (
            <button
              type="button"
              onClick={removeDefaultAddressInput}
              className={`border rounded ${
                isDefaultAddressSelected
                  ? 'border-transparent'
                  : 'border-green-600'
              } max-w-54 min-h-[80px] p-2 hover:cursor-pointer text-xs bg-gray-200 rounded`}
            >
              <AddressDetails
                fullName={`${user.first_name ? user.first_name : ''} ${
                  user.last_name ? user.last_name : ''
                }`}
                phone={user.phone}
                address={tempAddress.address}
                addressDescription={tempAddress.addressDescription}
              />
            </button>
          )}
          {(!user.address?.address ||
            (user.address?.address && !isTempAddress)) && (
            <button
              type="button"
              onClick={showAddressModal}
              className="w-44 min-h-[80px] flex justify-center items-center border text-sm bg-gray-200 rounded"
            >
              <AddIcon />
              Add Address
            </button>
          )}
        </div>
        {!isDefaultAddressSelected && !isTempAddress && (
          <div className="my-2">
            <p className="text-xs text-red-600">Please add delivery location</p>
          </div>
        )}
        <Modal isOpen={addressModal} onRequestClose={hideAddressModal}>
          <AddressFormModal
            closeModal={hideAddressModal}
            onFormSubmit={handleMapForm}
          />
        </Modal>
      </div>
      <hr className="my-2" />
      <div className="py-2">
        <h1 className="text-2xl">
          Delivery Option <span className="text-red-600">*</span>
        </h1>
        <div>
          <div className="flex items-center mb-3 mt-4">
            <input
              defaultChecked
              type="radio"
              value="delivery"
              {...register('deliveryOption', {
                required: 'Please select one delivery option',
              })}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label className="ml-2 font-medium text-gray-900">
              Delivery{' '}
              <span className="text-xs opacity-70">
                ( Delivery to your address )
              </span>
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              value="takeaway"
              {...register('deliveryOption', {
                required: 'Please select one delivery option',
              })}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label className="ml-2 font-medium text-gray-900">
              Takeaway{' '}
              <span className="text-xs opacity-70">
                ( Visit our restaurant )
              </span>
            </label>
          </div>
        </div>
      </div>
      <hr className="my-2" />
      <div className="py-2">
        <h1 className="text-2xl">
          Payment Method <span className="text-red-600">*</span>
        </h1>

        <div className="flex items-center mb-3 mt-4">
          <input
            defaultChecked
            type="radio"
            value="cash"
            {...register('paymentMethod', {
              required: 'Please select one delivery option',
            })}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
          />
          <label
            htmlFor="default-radio-1"
            className="ml-2 font-medium text-gray-900"
          >
            Cash
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            value="esewa"
            {...register('paymentMethod', {
              required: 'Please select one delivery option',
            })}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
          />
          <label
            htmlFor="default-radio-2"
            className="ml-2 font-medium text-gray-900"
          >
            Esewa
          </label>
        </div>
      </div>
    </div>
  );
}

export default DeliveryDetail;
