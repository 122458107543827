import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import BranchBanner from '../../components/Branch/BranchBanner';
import {
  BranchContents,
  HorizontalBranchTabs,
} from '../../components/Branch/BranchContents';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { fetchData } from '../../../services/RequestHandler/ApiRequest';
import { storeBranchList } from '../../../store/slice/productCategories';

const Branch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [productCategoriesList, setProductCategoriesList] = useState(null);
  const [loading, setLoading] = useState(true);
  const getActiveTab = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return HorizontalBranchTabs.includes(queryParams.get('tabs'))
      ? queryParams.get('tabs')
      : 'tabs-menu';
  };
  const [activeHorizontalTab, setActiveHorizontalTab] = useState(() =>
    getActiveTab(),
  );

  const handleHorizontalTabClick = async tabId => {
    await new Promise(resolve => setTimeout(resolve, 100));
    setActiveHorizontalTab(tabId);
    // if (tabId == 'tabs-menu') {
    //   setTimeout(() => scrollToTabContent(tabId), 250);
    // }
  };
  const { branches } = useSelector(state => state.productCategoriesPage);
  // const scrollToTabContent = tabId => {
  //   const element = document.getElementById(tabId);
  //   if (element) {
  //     const topOffset = element.offsetTop - 100;
  //     window.scrollTo({ top: topOffset, behavior: 'smooth' });
  //   }
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(
      'tabs',
      HorizontalBranchTabs?.find(tab => tab === activeHorizontalTab)
        ? activeHorizontalTab
        : 'tabs-menu',
    );
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  }, [activeHorizontalTab]);

  useEffect(() => {
    const fetchProductCategoriesByBranch = async () => {
      try {
        setLoading(true);
        const branchProducts = branches?.find(
          branchObj => branchObj?.branch?.slug === slug,
        );
        if (!branchProducts) {
          const data = await fetchData(`/branches/${slug}/products`);
          if (data?.status === 200) {
            dispatch(storeBranchList(data));
          }
          if (data.status === 404) {
            navigate('/not-found');
          }
          setProductCategoriesList(data?.data);
        } else {
          setProductCategoriesList(branchProducts);
        }
        setActiveHorizontalTab(() => getActiveTab());
        await new Promise(resolve => setTimeout(resolve, 500));
      } catch (e) {
        toast.error(e);
      } finally {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchProductCategoriesByBranch();
  }, [slug]);

  return (
    <>
      <Helmet>
        <title>Branch</title>
        <meta name="description" content="Branch page" />
      </Helmet>
      <div className="flex flex-col font-roboto custom-branch">
        <>
          <BranchBanner
            branchDetail={productCategoriesList?.branch}
            loading={loading}
          />
          <BranchContents
            activeHorizontalTab={activeHorizontalTab}
            handleHorizontalTabClick={handleHorizontalTabClick}
            productCategoriesList={productCategoriesList}
            loading={loading}
          />
        </>
      </div>
    </>
  );
};

export default Branch;
