import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import TinyMceContent from '../../components/common/TinyMceContent.jsx';
import { getPrivacyPolicy } from '../../../services/RequestHandler/ApiRequest.js';
import Loading from '../../components/common/Loading.jsx';

function PrivacyPolicy() {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fetchContent = async () => {
    setIsLoading(true);
    const description = await getPrivacyPolicy();
    setContent(description);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchContent();
  }, []);
  return (
    <div className="font-roboto">
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy page" />
      </Helmet>
      <div className="bg-gray-100 py-4 border-b border-gray-300 shadow">
        <div className="responsive-width px-2 flex justify-between items-center pt-8">
          <h1 className="text-4xl tracking-widest text-gray-700">
            Privacy Policy
          </h1>
        </div>
      </div>
      <div className="responsive-width px-2 mt-8">
        {isLoading ? (
          <Loading className="py-24" />
        ) : (
          <TinyMceContent content={content} />
        )}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
