// useAuthenticatedAction.js
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const useAuthenticatedAction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(state => state.login);

  const handleAuthenticatedAction = async (action, payload) => {
    if (!isAuthenticated) {
      sessionStorage.setItem('redirectPath', window.location.pathname);
      navigate(
        `/login?redirect=${encodeURIComponent(window.location.pathname)}`,
      );
    } else {
      await action(dispatch, payload);
    }
  };

  return { handleAuthenticatedAction };
};

export default useAuthenticatedAction;
