import HorizontalTabItem from './HorizontalTabItem';
import ScrollToElement from './TabsList/ScrollToElement';
import { Menu, About, Reviews, OtherBranches } from './TabsList';
import './BranchContent.css';
import Loading from '../common/Loading';

export const HorizontalBranchTabs = [
  'tabs-menu',
  'tabs-about',
  'tabs-reviews',
  'tabs-other-branches',
];

export const BranchContents = ({
  activeHorizontalTab,
  handleHorizontalTabClick,
  productCategoriesList,
  loading,
}) => {
  const tabsHorizontal = [
    {
      id: 'tabs-menu',
      label: 'Menu',
      component: <Menu productCategoriesList={productCategoriesList} />,
    },
    { id: 'tabs-about', label: 'About', component: <About /> },
    { id: 'tabs-reviews', label: 'Reviews', component: <Reviews /> },
    {
      id: 'tabs-other-branches',
      label: 'Other Branches',
      component: (
        <OtherBranches brancheList={productCategoriesList?.other_branches} />
      ),
    },
  ];

  return (
    <div>
      <div
        className="border top-[50px] bg-white sticky z-10 shadow-lg w-full"
        style={{
          opacity: loading ? 0.5 : 1,
          transition: 'opacity 1s ease-in-out',
        }}
      >
        <nav
          className="flex flex-row space-x-2 py-1 container mx-auto responsive-width "
          aria-label="Tabs"
          role="tablist"
          data-te-nav-ref
        >
          {tabsHorizontal.map(tab => (
            <HorizontalTabItem
              key={tab.id}
              id={tab.id}
              label={tab.label}
              active={activeHorizontalTab === tab.id}
              onClick={() => handleHorizontalTabClick(tab.id)}
            />
          ))}
        </nav>
      </div>
      {loading ? (
        <Loading className="py-24" />
      ) : (
        <ScrollToElement elementId={activeHorizontalTab}>
          {
            tabsHorizontal.find(tab => tab.id === activeHorizontalTab)
              ?.component
          }
        </ScrollToElement>
      )}
    </div>
  );
};
