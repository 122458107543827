import React from 'react';
import BannerTimeCountDown from './BannerTimeCountDown';
import BannerImage from './BannerImage';
import { useDispatch } from 'react-redux';
import { removeBanner } from '../../../../store/slice/homepage';

function BannerSingle({ banner, bannerNumber }) {
  const dispatch = useDispatch();
  const handleDealEnd = () => {
    dispatch(removeBanner({ bannerId: banner.id, bannerNumber }));
  };

  const endTime = banner.disc_end_date;
  const title = banner.name;
  const image = banner.image_url;
  return (
    <div>
      <div className="flex flex-col">
        <BannerTimeCountDown
          onDealEnd={handleDealEnd}
          endTime={endTime}
          title={title}
          banner={banner}
        />
        <BannerImage image={image} />
      </div>
    </div>
  );
}

export default BannerSingle;
