import React from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import { getFormattedDate } from '../../../utils/getFormattedDate';
import Animate from '../common/Animate/Animate';
import TinyMceContent from '../common/TinyMceContent';

function BlogDetail({ blog }) {
  return (
    <div>
      <Animate>
        <h1 className="text-4xl py-2">{blog.name}</h1>
        <div className="flex gap-2 text-xs text-gray-900 pb-4">
          <div className="date">{getFormattedDate(blog.created_at)}</div>
          <div className="user hidden">{blog.created_by}</div>
          <div className="comments text-gray-400 hidden">
            <CommentIcon fontSize="inherit" />
            <span>3</span>
          </div>
        </div>
      </Animate>
      <Animate>
        <img src={blog.image_url} alt={blog.name} />
      </Animate>
      <Animate>
        <TinyMceContent content={blog.description} />
        <div
          className="py-4 text-gray-500"
          dangerouslySetInnerHTML={{ __html: blog.description }}
        ></div>
      </Animate>
    </div>
  );
}

export default BlogDetail;
