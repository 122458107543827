import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 100, // Adjust the z-index value as needed
  },
  content: {
    top: '10%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: 'auto',
    transform: 'translate(-50%, 0%)',
    backgroundColor: 'white',
    padding: 0,
    maxHeight: '90vh',
    overflow: 'hidden',
    maxWidth: '90%',
  },
};
ReactModal.setAppElement('#root');
function Modal({ isOpen, onRequestClose, children, ...props }) {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      {...props}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
