import { createSlice } from '@reduxjs/toolkit';

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: {
    favoriteItems: [],
    totalFavorites: 0,
  },
  reducers: {
    addFavorite: (state, action) => {
      const { product } = action.payload;
      const existingFavoriteItem = state.favoriteItems.find(
        item => item.id === product.id,
      );
      if (!existingFavoriteItem) {
        state.favoriteItems.push(product);
        state.totalFavorites++;
      }
    },
    removeFavorite: (state, action) => {
      const { itemId } = action.payload;
      const itemIndex = state.favoriteItems.findIndex(
        item => item.id === itemId,
      );

      if (itemIndex !== -1) {
        state.favoriteItems.splice(itemIndex, 1);
        state.totalFavorites--;
      }
    },
    clearFavorites: state => {
      state.favoriteItems = [];
      state.totalFavorites = 0;
    },
    refreshFavourites: (state, action) => {
      state.favoriteItems = action.payload.favoriteItems || [];
      state.totalFavorites = action.payload.favoriteItems.length || 0;
    },
  },
});

export const {
  addFavorite,
  removeFavorite,
  clearFavorites,
  refreshFavourites,
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
