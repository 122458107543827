import React from 'react';
import SocialMediaIcons from '../common/SocialMediaIcons';
import { Link } from 'react-router-dom';

function FooterContent() {
  return (
    <div className="p-3 responsive-width  text-gray-600 mb-10 overflow-hidden ">
      <div>
        <div className="flex flex-col md:flex-row gap-2 lg:gap-10 justify-between">
          <div className="flex w-full md:w-[25%] flex-col gap-2">
            <p className="font-bold uppercase text-sm">We&apos;re lavapalu</p>
            <Link to="/about">About Us</Link>
            <p>Available Area</p>
            <p>Delivery Charges</p>
            <Link to="/blog">Blog</Link>
            <p className="text-xs py-6 ">
              <span className="uppercase font-bold"> SERVICE HOUR </span>09:00
              AM to 9:00 PM (NST)
            </p>
          </div>
          <div className="flex w-full md:w-[20%] flex-col gap-2">
            <p className="font-bold uppercase text-sm">Get Help</p>
            <p>How to Order?</p>
            <p>FAQs</p>
            <p>Conact Us</p>
          </div>
          <div className="flex w-full md:w-[25%] flex-col gap-2">
            <p className="font-bold uppercase text-sm">Call Us</p>
            <p>
              Gwarko:
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+977 9851104182"
              >
                {` `}+977 9851104182
              </a>
            </p>
          </div>
          <div className="flex w-full md:w-[30%] flex-col gap-2">
            <p className="font-bold uppercase text-sm">Connect with us</p>
            <div>
              <SocialMediaIcons />
            </div>
          </div>
        </div>
        <hr className="my-4" />
        <div className="flex justify-between flex-wrap">
          <p className="text-xs font-light">
            <Link to="/terms-of-uses">Terms of Usage</Link>
            {` | `}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="text-xs font-light ">
            © 2023 Lavaplau Pvt. Ltd. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterContent;
