import React from 'react';
import { useInView } from 'react-intersection-observer';
import './animate.css';

function Animate({ children, className, ...props }) {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation only triggers once
  });
  return (
    <div
      ref={ref}
      {...props}
      className={`opacity-0 z-0 ${
        inView ? 'animated-element' : ''
      } ${className}`}
    >
      {children}
    </div>
  );
}

export default Animate;
