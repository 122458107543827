import React from 'react';

function TinyMceContent({ content, className = '', ...props }) {
  return (
    <div
      {...props}
      className={`py-4 text-gray-500 ${className}`}
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
}

export default TinyMceContent;
