import React, { useState } from 'react';
import EmailInput from './EmailInput';
import VerificationCodeInput from './VerificationCodeInput';
import NewPassword from './NewPassword';

export default function () {
  const [currentPage, setCurrentPage] = useState(1);
  const [globalEmail, setGlobalEmail] = useState('');
  const handleCurrentPage = page => {
    setCurrentPage(page);
  };

  localStorage.clear();
  if (currentPage === 1)
    return (
      <EmailInput
        handleCurrentPage={handleCurrentPage}
        setGlobalEmail={setGlobalEmail}
      />
    );
  if (currentPage === 2)
    return (
      <VerificationCodeInput
        handleCurrentPage={handleCurrentPage}
        globalEmail={globalEmail}
      />
    );
  // if (currentPage === 'new-password')
  return (
    <NewPassword
      handleCurrentPage={handleCurrentPage}
      globalEmail={globalEmail}
    />
  );
}
