import React from 'react';
import Animate from '../common/Animate/Animate';

const HeroSection = ({ title = '', bgImage }) => {
  return (
    <div className="hero-container bg-gray-300 text-white h-[50vh] flex flex-col justify-center items-center relative">
      <img
        src={bgImage}
        className="w-full h-full object-cover absolute top-0 left-0"
      />
      <div className="object-cover bg-black opacity-40 object-center h-full w-full absolute inset-0" />
      <Animate>
        <h1 className="text-3xl z-0 text-center uppercase font-bold">
          {title}
        </h1>
      </Animate>
    </div>
  );
};

export default HeroSection;
