import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    totalItems: 0,
  },
  reducers: {
    addItem: (state, action) => {
      const { product, cart } = action.payload;
      const existingItem = state.items.find(item => item.id === product.id);

      if (existingItem) {
        existingItem.quantity = cart?.unit;
      } else {
        state.items.push({ ...product, quantity: cart?.unit });
      }
      state.totalItems++;
    },
    removeItem: (state, action) => {
      const { itemId } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === itemId);
      const existingItem = state.items[itemIndex];
      if (existingItem && existingItem?.quantity > 1) {
        existingItem.quantity--;
        state.totalItems--;
      }
    },
    removeAllItems: (state, action) => {
      const { itemId } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === itemId);

      if (itemIndex !== -1) {
        state.totalItems -= state.items[itemIndex].quantity;
        state.items.splice(itemIndex, 1);
      }
    },
    updateQuantity: (state, action) => {
      const { itemId, quantity } = action.payload;
      const existingItem = state.items.find(item => item.id === itemId);

      if (existingItem) {
        state.totalItems = state.totalItems - existingItem.quantity + quantity;
        existingItem.quantity = quantity;
      }
    },
    clearCart: state => {
      state.items = [];
      state.totalItems = 0;
    },
    refreshCart: (state, action) => {
      state.items = action.payload.refreshCarts || [];
      state.totalItems = action.payload.totalItems || 0;
    },
  },
});

export const calculateCartTotalPrice = state =>
  state.cart.items.reduce((total, item) => {
    let price = parseFloat(item.amount);
    if (item.discount_status) price -= parseFloat(item.discount);
    return total + price * item.quantity;
  }, 0);

export const {
  addItem,
  removeItem,
  removeAllItems,
  updateQuantity,
  clearCart,
  refreshCart,
} = cartSlice.actions;

export default cartSlice.reducer;
