import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderHistory } from '../../../services/RequestHandler/ApiRequest';
import Modal from '../common/Modal';
import CancelOrder from '../Modal/CancelOrder';
import { postData } from '../../../services/RequestHandler/ApiPostRequest';
import { toast } from 'react-toastify';
import { getMe } from '../../../services/RequestHandler/AuthRequest';
import SubmitSpinner from '../common/SubmitSpinner';

const orderHistory = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const hideConfirmModal = () => setConfirmModalOpen(false);
  const onClickCancel = (orderCode, orderDetails) => {
    setCurrentOrder(orderDetails);
    setConfirmModalOpen(true);
  };
  const {
    ordersList,
    loading: isLoading,
    loadMoreOrders,
  } = useSelector(state => state.orders);
  useEffect(() => {
    dispatch(fetchOrderHistory(page));
  }, [page]);

  if (ordersList.length === 0 && !isLoading) {
    return (
      <div className="max-w-3xl px-1">
        <h3 className="uppercase text-sm font-bold text-gray-500 tracking-tighter	 pb-8">
          Order History
        </h3>
        <span>You don&apos;t have any orders.</span>
      </div>
    );
  }

  const handleSubmitForm = async order_code => {
    try {
      setLoading(true);
      if (!order_code || !order_code?.startsWith('#')) {
        return toast.error('Invalid order code selected !');
      }
      const body = {
        order_code,
      };
      const response = await postData('/orders/cancel', body);
      if (response?.status === 201) {
        toast.success(response?.message || 'Order Cancelled');
      } else {
        toast.error(response?.message || 'Failed to Cancel Order');
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(fetchOrderHistory());
      getMe(dispatch);
      setLoading(false);
      setConfirmModalOpen(false);
    }
  };

  return (
    <>
      <div className="max-w-3xl px-1">
        <h3 className="uppercase text-sm font-bold text-gray-500 tracking-tighter	 pb-8 md:pt-0 pt-4">
          Order History
        </h3>
        {ordersList?.map(orderDetails => {
          const orderCode = orderDetails?.length
            ? orderDetails[0]?.order_code
            : null;

          if (!orderCode) {
            return null;
          }

          return (
            <div
              key={orderCode}
              className="bg-white py-2 mb-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-md"
            >
              {orderDetails[0]?.order_status !== 'pending' ? null : (
                <div className="mr-2 md:mr-4 flex md:hidden block">
                  <button
                    className="bg-primary text-white rounded px-2 py-1.5 text-sm ml-auto"
                    onClick={() => onClickCancel(orderCode, orderDetails)}
                  >
                    Cancel Order
                  </button>
                </div>
              )}
              <div className="flex flex-row border-b mb-2 pb-2 justify-between md:ml-4 ml-2 md:mt-2">
                <div>
                  <span className=" font-bold text-xs sm:text-sm">
                    {orderCode}
                  </span>
                  <p className="text-xs font-extralight flex flex-row gap-1 ">
                    Placed Date:
                    <span>
                      {new Date(orderDetails[0]?.created_at).toLocaleDateString(
                        'en-US',
                        {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        },
                      )}
                    </span>
                    <span>
                      {new Date(orderDetails[0]?.created_at).toLocaleTimeString(
                        'en-US',
                        {
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: true,
                        },
                      )}
                    </span>
                  </p>
                </div>
                {orderDetails[0]?.order_status !== 'pending' ? null : (
                  <div className="mr-2 md:mr-4 md:visible invisible">
                    <button
                      className="bg-primary text-white rounded px-2 py-1.5 text-sm"
                      onClick={() => onClickCancel(orderCode, orderDetails)}
                    >
                      Cancel Order
                    </button>
                  </div>
                )}
              </div>
              {orderDetails.map(order => (
                <div
                  key={order?.product_id}
                  className="flex mb-2 ml-2 md:ml-4 justify-between"
                >
                  <div className="flex mb-2">
                    <div>
                      <img
                        src={order?.product?.image_url}
                        alt={order?.product?.name}
                        className="min-w-16 w-16 md:mt-1"
                      />
                    </div>
                    <div className="ml-2">
                      <div className="flex flex-row">
                        <span className="font-semibold text-xs sm:text-sm">
                          {order?.product?.name}
                        </span>
                      </div>
                      <div className="flex flex-row">
                        <span className="text-xs font-extralight  ">
                          Cost&nbsp;&nbsp;&nbsp;:
                        </span>
                        <span className="text-xs font-extralight  ">
                          &nbsp;Rs.&nbsp;{order?.amount_per_unit}
                        </span>
                      </div>
                      <p className="text-xs font-extralight  ">
                        Items&nbsp;:&nbsp;{order?.quantity}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span
                      className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium mr-2 md:mr-4 ${order?.order_status === 'pending' ? ' text-primary border-[1px] border-primary' : ''} ${order?.order_status === 'cancelled' || order?.order_status === 'rejected' ? ' text-red-600 border-[1px] border-red-600' : ''} ${order?.order_status === 'completed' ? ' text-[#07bc0c] border-[1px] border-[#07bc0c]' : ''}`}
                    >
                      <span className="italic">{order?.order_status}</span>
                    </span>
                  </div>
                </div>
              ))}
              <div className="flex justify-end border-t mt-2 pt-2 px-6 ">
                <div>
                  <span className="text-xs font-extralight  ">
                    Total Amount :&nbsp;
                  </span>
                  <span className="text-primary font-bold ">
                    Rs. {orderDetails[0]?.subtotal_amount}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex flex-col items-center pt-3">
          {isLoading ? (
            <div className="py-3 text-center">
              <SubmitSpinner />
              Loading Orders...
            </div>
          ) : null}
          {!ordersList?.length && !isLoading ? (
            <div className="py-3 text-center">No Reviews Yet</div>
          ) : !loadMoreOrders || isLoading ? null : (
            <button
              onClick={() => setPage(prev => prev + 1)}
              disabled={isLoading}
              className="w-sm uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-3 text-center"
            >
              Show More Orders
            </button>
          )}
        </div>
      </div>
      <Modal isOpen={confirmModalOpen} onRequestClose={hideConfirmModal}>
        <CancelOrder
          closeModal={hideConfirmModal}
          orderCode={currentOrder}
          orderDetails={currentOrder}
          submitForm={handleSubmitForm}
          loading={loading}
        />
      </Modal>
    </>
  );
};
export default orderHistory;
