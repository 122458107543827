import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  postCart,
  postFavourite,
} from '../../../services/RequestHandler/ApiPostRequest';
import useAuthenticatedAction from '../../../utils/auth';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
const ProductContent = ({ product, productDetail }) => {
  const { handleAuthenticatedAction } = useAuthenticatedAction();
  const { favoriteItems } = useSelector(state => state.favorite);
  const [assigningFav, setAssigningFav] = useState(false);
  const [addingCart, setAddingCart] = useState(false);
  const handleFavoriteClick = async payload => {
    setAssigningFav(true);
    await handleAuthenticatedAction(postFavourite, {
      ...payload,
      removeFavourite: false,
    });
    setAssigningFav(false);
  };

  const handleAddToCart = async () => {
    setAddingCart(true);
    await handleAuthenticatedAction(postCart, { ...product, unit: 1 });
    setAddingCart(false);
  };

  const productInFavourite = !!favoriteItems?.find(
    favProduct => favProduct?.id === product?.id,
  );
  return (
    <div
      className="hover:shadow-[inset_-2px_-2px_5px_#e0e0e0] cursor-pointer transition duration-250 ease-in-out transform hover:scale-101 rounded-1xl "
      type="button"
    >
      <div
        className="border-box flex flex-row  justify-between  hover:shadow-[0px_2px_5px_rgba(17,17,26,0.1),_0px_4px_7px_rgba(17,17,26,0.1),_0px_5px_8px_rgba(17,17,26,0.1)] rounded-1xl
    "
      >
        <div className=" py-4 px-4 ">
          <div className="flex flex-col">
            <span className="text-left">{product?.name}</span>

            <span className="text-gray-600 ">{productDetail}</span>
          </div>
        </div>

        <div className=" py-4 px-4 text-black ">
          <div className="flex text-center items-center justify-center gap-2">
            <span className="text-center text-primary">Rs.</span>
            {!product?.discount_status ? null : (
              <span className="text-center line-through text-gray-500">
                {product?.price_old}
              </span>
            )}
            <span className="text-center text-primary">
              {product?.price_final}
            </span>

            <button onClick={handleAddToCart} disabled={addingCart}>
              <div
                className={`rounded-full flex items-center justify-center border border-gray-300 text-gray-900 hover:text-white hover:bg-primary hover:border-primary w-6 h-6 ${addingCart ? 'opacity-50 cursor-wait' : ' '}`}
              >
                <span className="font-bold border flex items-center justify-center hover:border-primary  rounded-full w-6 h-6 ">
                  +
                </span>
              </div>
            </button>
            <button
              onClick={() => handleFavoriteClick(product)}
              disabled={assigningFav}
            >
              <div
                className={`rounded-full flex items-center justify-center border border-gray-300 text-gray-900 hover:text-white hover:bg-primary hover:border-primary ${
                  productInFavourite
                    ? ' bg-primary border-primary text-white '
                    : ''
                } 
                ${assigningFav ? 'opacity-50 cursor-wait' : ' '}
                 w-6 h-6`}
              >
                <FavoriteBorderIcon fontSize="sm" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductContent;
