import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postRegistration } from '../../../../services/RequestHandler/AuthRequest';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin, FacebookLogin } from '../OAuth.jsx';
import Spinner from '../../../components/common/Spinner';

function RegisterPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);
  const { isAuthenticated } = useSelector(state => state.login);
  const [authenticationChecked, setAuthenticationChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };
  const handleToggleConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const watchPassword = watch('password');

  const onSubmit = async payload => {
    setLoading(true);
    const response = await postRegistration(dispatch, payload);
    setLoading(false);

    if (response) {
      // navigate('/login');
      navigate('/');
      reset();
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
    setAuthenticationChecked(true);
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (!authenticationChecked || loading) {
    return <Spinner />;
  }

  return (
    <section className="border-red-500 bg-accentContainer h-full responsive-width">
      <Helmet>
        <title>Signup</title>
        <meta name="description" content="Signup page" />
      </Helmet>
      <div className="bg-white py-5 px-10 rounded-md shadow-lavapaluAccent max-w-sm mx-auto mx-5 my-8 sm:w-auto md:border">
        <div>
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl  text-center">
            Sign Up
          </h1>

          <form
            className="mt-4 space-y-4 md:space-y-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="login-with flex flex-col xs:flex-row justify-between gap-3 xs:gap-2 ">
              <div className="flex-grow">
                <GoogleLogin setLoading={setLoading} />
              </div>
              <div className="flex-grow">
                <FacebookLogin setLoading={setLoading} />
              </div>
            </div>
            <div className="mt-7 grid grid-cols-3 items-center text-gray-500">
              <hr className="border-gray-500" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-500" />
            </div>
            <div className="flex flex-row gap-4 justify-between">
              <div className="w-full">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  First Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Eg. Aakash"
                  {...register('firstName', {
                    required: true,
                    maxLength: 20,
                  })}
                />
                {errors.firstName && errors.firstName.type === 'required' && (
                  <span className="text-red-600">First Name is required</span>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Last Name <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Eg. Shrestha"
                  {...register('lastName', {
                    required: true,
                    maxLength: 20,
                  })}
                />
                {errors.lastName && errors.lastName.type === 'required' && (
                  <span className="text-red-600">Last Name is required</span>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email <span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Eg. example@gmail.com"
                  {...register('email', {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                {errors.email && errors.email.type === 'required' && (
                  <span className="text-red-600">Email is required</span>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Mobile Number <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Eg. 98452xxxxx"
                  {...register('phone', {
                    required: true,
                    minLength: 6,
                    maxLength: 20,
                  })}
                />
                {errors.phone && errors.phone.type === 'required' && (
                  <span className="text-red-600">
                    Mobile Number is required
                  </span>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Password <span className="text-red-600">*</span>
              </label>
              <div className="relative flex w-full h-full">
                <input
                  type={passwordShown ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter your Password"
                  {...register('password', {
                    required: 'Password is required',
                    maxLength: {
                      value: 20,
                      message: 'Maximum password length is 20',
                    },
                    minLength: {
                      value: 8,
                      message: 'Minimum password length is 8',
                    },
                  })}
                />

                <div className="absolute right-0 flex justify-center items-center h-full mr-3 cursor-pointer">
                  {passwordShown ? (
                    <VisibilityOutlinedIcon onClick={handleToggle} />
                  ) : (
                    <VisibilityOffOutlinedIcon onClick={handleToggle} />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="text-red-600">{errors.password.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="password_confirmation"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Confirm Password <span className="text-red-600">*</span>
              </label>
              <div className="relative flex w-full h-full">
                <input
                  type={passwordShownConfirm ? 'text' : 'password'}
                  id="password_confirmation"
                  name="password_confirmation"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Confirmation Password"
                  {...register('password_confirmation', {
                    required: 'Confirm password is required',
                    validate: value =>
                      value === watchPassword || 'The passwords do not match',
                  })}
                />

                <div className="absolute right-0 flex justify-center items-center h-full mr-3 cursor-pointer">
                  {passwordShownConfirm ? (
                    <VisibilityOutlinedIcon onClick={handleToggleConfirm} />
                  ) : (
                    <VisibilityOffOutlinedIcon onClick={handleToggleConfirm} />
                  )}
                </div>
              </div>
              {errors.password_confirmation && (
                <p className="text-red-600">
                  {errors.password_confirmation.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-full uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 text-center"
            >
              Signup
            </button>

            <p className="text-sm text-center font-light text-gray-500 ">
              Already have an account?&nbsp;
              <NavLink
                className="text-sm font-medium text-primary-600 hover:underline "
                to="/login"
              >
                Login
              </NavLink>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}

export default RegisterPage;
