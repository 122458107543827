import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../../../services/RequestHandler/AuthRequest';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Helmet } from 'react-helmet-async';
import { GoogleLogin, FacebookLogin } from '../OAuth.jsx';
import { redirectBack } from '../../../../utils/redirectBack.js';
import Spinner from '../../../components/common/Spinner';

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const { isAuthenticated } = useSelector(state => state.login);
  const [_, setAuthenticationChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async payload => {
    setLoading(true);
    const response = await postLogin(dispatch, payload);
    setLoading(false);
    if (response) {
      reset();
      redirectBack(navigate);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
    setAuthenticationChecked(true);
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className="border-red-500 bg-accentContainer h-full  flex items-center justify-center ">
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login page" />
      </Helmet>
      {loading ? <Spinner /> : null}
      <div className="bg-white py-5 px-5 sm:px-10 rounded-md shadow-lavapaluAccent w-full md:max-w-xs mx-5 my-8 w-auto border">
        <div>
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2x text-center">
            Login
          </h1>

          <form
            className="mt-4 space-y-4 md:space-y-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="login-with flex flex-col xs:flex-row justify-between gap-3 xs:gap-2 xs:min-w-96">
              <div className="flex-grow">
                <GoogleLogin setLoading={setLoading} />
              </div>
              <div className="flex-grow hidden">
                <FacebookLogin setLoading={setLoading} />
              </div>
            </div>
            <div className="mt-7 grid grid-cols-3 items-center text-gray-500">
              <hr className="border-gray-500" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-500" />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Email or Phone <span className="text-red-600">*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                placeholder="Enter email or phone number"
                {...register('email', {
                  required: true,
                })}
              />
              {errors.email && errors.email.type === 'required' && (
                <span className="text-red-600">Email or Phone is required</span>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password <span className="text-red-600">*</span>
              </label>
              <div className="relative flex w-full h-full">
                <input
                  type={passwordShown ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-bodyText rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter password"
                  {...register('password', {
                    required: 'Password is required',
                    maxLength: {
                      value: 20,
                      message: 'Maximum password length is 20',
                    },
                    minLength: {
                      value: 8,
                      message: 'Minimum password length is 8',
                    },
                  })}
                />

                <div className="absolute right-0 flex justify-center items-center h-full mr-3 cursor-pointer">
                  {passwordShown ? (
                    <VisibilityOutlinedIcon onClick={handleToggle} />
                  ) : (
                    <VisibilityOffOutlinedIcon onClick={handleToggle} />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="text-red-600">{errors.password.message}</p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    name="remember"
                    {...register('remember')}
                    className="w-4 h-4 border border-gray-300 rounded-md bg-gray-50 focus:ring-3 focus:ring-primary-300 "
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label className="text-gray-500 ">Remember me</label>
                </div>
              </div>
              <NavLink
                className="text-sm font-medium text-primary-600 hover:underline "
                to="/forgot-password"
              >
                Forgot password?
              </NavLink>
            </div>
            <button
              type="submit"
              className="w-full uppercase bg-primary text-white hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 text-center"
            >
              Login
            </button>
            <p className="text-sm text-center font-light text-gray-500 ">
              Don&apos;t have an account?&nbsp;
              <NavLink
                className="text-sm font-medium text-primary-600 hover:underline "
                to="/register"
              >
                Sign Up
              </NavLink>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
