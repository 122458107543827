import { createSlice, current } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationList: [],
    unseenNotifications: 0,
    totalNotifications: 0,
  },
  reducers: {
    refreshNotifications: (state, action) => {
      state.notificationList = action.payload?.notifications || [];
      state.unseenNotifications =
        action.payload?.notifications?.filter(({ seen }) => !seen).length || 0;
      state.totalNotifications = action.payload?.notifications?.length || 0;
    },
    updateNotifications: (state, action) => {
      const { notificationSlug } = action.payload;
      const existingNotification = current(state).notificationList.find(
        item => item?.slug === notificationSlug,
      );
      if (existingNotification?.slug && !existingNotification?.seen) {
        const updatedNotification = { ...existingNotification };
        updatedNotification.seen = true;
        state.notificationList = state.notificationList.map(notification => {
          if (notification.slug === notificationSlug) {
            return updatedNotification;
          }
          return notification;
        });

        state.unseenNotifications = Math.max(0, state.unseenNotifications - 1);
      }
    },
    updateNotificationReview: (state, action) => {
      const { notificationSlug } = action.payload;
      const existingNotification = current(state).notificationList.find(
        item => item?.slug === notificationSlug,
      );
      if (existingNotification?.slug) {
        const updatedNotification = { ...existingNotification };
        updatedNotification.review = true;
        state.notificationList = state.notificationList.map(notification => {
          if (notification.slug === notificationSlug) {
            return updatedNotification;
          }
          return notification;
        });
      }
    },
  },
});

export const {
  refreshNotifications,
  updateNotifications,
  updateNotificationReview,
} = notificationSlice.actions;

export default notificationSlice.reducer;
