import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../../components/HeroSection/HeroSection';
import { serializeFormQuery } from '../../../helper/serializeFormQuery';
import Loading from '../../components/common/Loading';
import RestroWithHeading from '../../components/common/RestroWithHeading';
import { getBranchBySearchParam } from '../../../services/RequestHandler/ApiRequest';

function SearchResult() {
  const [isLoading, setIsLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const { branches: otherBranches } = useSelector(store => store.homePage);
  let [searchParams, setSearchParams] = useSearchParams();
  const defaultQuery = searchParams.get('query');
  const targetDivRef = useRef(null);

  function handleSubmit(event) {
    event.preventDefault();
    setSearchParams(() => serializeFormQuery(event.target));
  }
  async function fetchData() {
    setIsLoading(true);
    const search = searchParams.get('query');
    const branches = await getBranchBySearchParam({ search });
    setBranches(branches);
    setIsLoading(false);
  }
  const handleViewFocus = () => {
    // Get the position of the target div
    const targetDivPosition = targetDivRef.current.offsetTop;
    window.scrollTo({
      top: targetDivPosition - 150,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    // targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    handleViewFocus();
    fetchData();
  }, [searchParams]);
  return (
    <div className="font-roboto">
      <Helmet>
        <title>Search</title>
        <meta name="description" content="Search page" />
      </Helmet>
      <HeroSection title="Search" subtitle="Find your food" bgImage="" />
      <div className="responsive-width" ref={targetDivRef}>
        <form
          onSubmit={handleSubmit}
          className="flex justify-center items-center w-full mt-12 px-4 xs:px-12"
        >
          <div className="max-w-[800px] w-full flex gap-0 items-stretch rounded-md">
            <input
              type="text"
              defaultValue={defaultQuery}
              id="query"
              name="query"
              className="text-black rounded-l-lg border border-primary text-sm focus:ring-none block p-2.5 w-full ring-primary focus-visible:outline-none focus:ring-1"
              placeholder="Search food Items"
              required
            />
            <div>
              <button
                type="submit"
                className="px-12 border border-primary rounded-r-lg h-full font-medium text-white bg-primary"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        {isLoading && <Loading className="py-12" />}
        {!isLoading &&
          (branches?.length > 0 ? (
            <RestroWithHeading heading="Restaurants" restrosList={branches} />
          ) : (
            <>
              <h2 className="text-3xl font-bold my-12 capitalize text-center">
                No restaurants found !!
              </h2>
              <RestroWithHeading
                heading="Other Restaurants"
                restrosList={otherBranches}
              />
            </>
          ))}
      </div>
    </div>
  );
}

export default SearchResult;
