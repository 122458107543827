import React from 'react';
import { Link } from 'react-router-dom';
import CommentIcon from '@mui/icons-material/Comment';
import { getFormattedDate } from '../../../utils/getFormattedDate';
import TinyMceContent from '../common/TinyMceContent';

function Blog({ blog }) {
  return (
    <div className="blogs-list flex flex-col gap-8 md:flex-row items-center">
      <Link className="w-2/5" to={`/blog/${blog.slug}`}>
        <img
          className="h-[250px] w-full md:w-[450px] object-cover"
          src={blog.image_url}
          alt={blog.name}
        />
      </Link>
      <div className="flex flex-col justify-between gap-2 mt-4 w-3/5">
        <div className="flex gap-2 text-xs text-gray-900 pb-4">
          <div className="date">{getFormattedDate(blog.created_at)}</div>
          <div className="user hidden"> {blog.created_by}</div>
          <div className="comments text-gray-400 hidden">
            <CommentIcon fontSize="inherit" />
            <span>3</span>
          </div>
        </div>
        <div className="blog-title">
          <Link to={`/blog/${blog.slug}`}>
            <p className="font-medium text-xl tracking-wider hover:text-green-800">
              {blog.name}
            </p>
          </Link>
        </div>
        <div className="description text-sm text-gray-600">
          <TinyMceContent
            content={
              blog.description?.length < 160
                ? blog?.description?.slice(0, 160)
                : blog?.description?.slice(0, 160) + '<strong>...</strong>'
            }
          />
        </div>
        <div className="read-more-btn">
          <Link
            to={`/blog/${blog.slug}`}
            className="transition py-2 inline-block px-4 border border-primary bg-primary rounded-full text-white hover:text-primary hover:bg-transparent duration-300"
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Blog;
