/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  careerList: [],
  careerDetail: {},
  notFound: false,
  isCareerListLoading: false,
};
const careerListSlice = createSlice({
  name: 'career',
  initialState,
  reducers: {
    storeCareerPageData: (state, action) => {
      state.careerList = action.payload || [];
      state.isCareerListLoading = false;
    },
    storeCareerDetailData: (state, action) => {
      state.careerDetail = action.payload?.data || {};
      state.notFound = action.payload?.status !== 200;
    },
    careerListLoading: state => {
      state.isCareerListLoading = true;
    },
  },
});
export const { storeCareerPageData, storeCareerDetailData, careerListLoading } =
  careerListSlice.actions;
export default careerListSlice.reducer;
