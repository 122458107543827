function OAuthLogin({ icon, children }) {
  return (
    <div className=" hover:bg-primary hover:opacity-80 hover:text-white transition duration-300 cursor-pointer border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block  px-2 py-0 ">
      <div className="flex w-full gap-1 items-center text-center  justify-center">
        <div>{icon}</div>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default OAuthLogin;
