/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.clear();
    },
    updateUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    expired: state => {
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout, expired, updateUser } = loginSlice.actions;
export default loginSlice.reducer;
