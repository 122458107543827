import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';
import { removeCart } from '../../../services/RequestHandler/ApiDeleteRequest';

function CartDropDown() {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.cart);
  const handleCartItemRemoveClick = async payload => {
    await removeCart(dispatch, payload);
  };
  const { branches } = useSelector(state => state?.homePage);
  const getBranchNameById = id => {
    if (branches) return branches?.find(branch => branch?.id === id)?.name;
    else return null;
  };
  return (
    <>
      <div className="text-center border-b pb-1">CARTS</div>
      <div className="pl-2">
        <div className="h-[50vh] menu-items overflow-y-auto">
          {items?.length ? (
            <div className="mr-2">
              {items?.map((item, index) => (
                <div
                  key={item.id}
                  className={`py-2 px-2 rounded flex gap-4 items-center justify-between ${
                    index ? 'border-t' : ''
                  }`}
                >
                  <div>
                    <p className="text-xs font-medium">
                      {item?.name?.slice(0, 20)}
                      {item?.name?.length > 20 ? '...' : ''}
                    </p>
                    <span className="text-[0.6rem] font-extralight ">
                      {getBranchNameById(item?.branch_id)}
                    </span>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <span className="bg-gray-200 h-5 w-5 flex text-xs justify-center items-center rounded-full">
                      {item?.quantity}
                    </span>
                    <span className="text-xs text-primary ">
                      Rs.{Math.ceil(item?.quantity * item?.price_final)}
                    </span>
                    <button
                      onClick={() => handleCartItemRemoveClick(item)}
                      className="opacity-50 hover:opacity-70 "
                    >
                      <CloseIcon className="hover:text-[red]" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-xs mt-4 text-center">
              No items!! please add items to your cart to list here
            </div>
          )}
        </div>
        <div className="flex justify-end  m-2">
          {items?.length ? (
            <NavLink
              to="/checkout"
              className="w-full btn-primary flex justify-center items-center py-1 gap-1 rounded text-white uppercase text-xs"
            >
              <p>Proceed to checkout</p>
              <ArrowRightAltIcon />
            </NavLink>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default CartDropDown;
