export const getFormattedDate = date => {
  // Convert the input string to a Date object
  const dateObject = new Date(date);

  // Define a function to get the month name
  const getMonthName = monthIndex => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthIndex];
  };

  // Get the year, month, and day from the date object
  const year = dateObject.getFullYear();
  const monthIndex = dateObject.getMonth();
  const day = dateObject.getDate();

  // Get the formatted month name
  const formattedMonth = getMonthName(monthIndex);

  // Create the formatted date string
  return `${formattedMonth} ${day}, ${year}`;
};
