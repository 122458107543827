import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '../Modal';
import CloseBtn from '../../common/Button/CloseBtn';

function ImageUploader({ innerRef, onImageChange, ...props }) {
  const [src, setSrc] = useState(null);
  const [maxZoom, setMaxZoom] = useState(100);
  const [imageResolution, setImageResolution] = useState({
    width: 1,
    height: 1,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radius, setRadius] = useState(50);
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [completedCrop, setCompletedCrop] = useState(crop);
  const imgRef = useRef(null);

  const handleCloseModalRequest = () => {
    setIsModalOpen(false);
    setSrc(null);
    innerRef.current.value = '';
  };
  const changeCropRadius = () => {
    const { width, height } = imageResolution;
    const tempCrop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: radius,
          height: radius,
        },
        1 / 1,
        width,
        height,
      ),
      width,
      height,
    );
    setCrop(tempCrop);
  };
  useEffect(() => {
    changeCropRadius();
  }, [radius]);
  const onImageLoad = e => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    setImageResolution({ width, height });
    if (width >= height) setMaxZoom(() => Math.ceil((height / width) * 100));
    else setMaxZoom(() => Math.ceil((width / height) * 100));
    setRadius(60);
  };

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = e => {
        setSrc(e.target.result);
        setIsModalOpen(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = image => {
    imgRef.current = image;
    setCrop({ ...crop, width: 50, height: 50 });
  };

  const onCropChange = crop => {
    setCrop(crop);
  };
  useEffect(() => {
    setCompletedCrop(crop);
  }, [crop]);

  const onCropComplete = crop => {
    setCompletedCrop(crop);
  };
  const handleSliderChange = e => setRadius(e.target.value);

  const handleCropImage = () => {
    if (imgRef.current && completedCrop) {
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height,
      );
      const croppedImageBase64 = canvas.toDataURL('image/jpeg'); // Adjust format as needed
      onImageChange(croppedImageBase64);
      handleCloseModalRequest();
      // Now you can send croppedImageBase64 to the server or perform further actions
      // const downloadLink = document.createElement('a');
      // downloadLink.href = croppedImageBase64;
      // downloadLink.download = 'cropped-image.jpg'; // You can set the filename here
      // // Trigger a click event on the download link to start the download
      // downloadLink.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={innerRef}
        onChange={onSelectFile}
        {...props}
      />
      {src && (
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModalRequest}>
          <CloseBtn
            onClick={handleCloseModalRequest}
            className="absolute top-1 right-1 p-1 text-gray-500 hover:text-gray-900"
          />
          <h2 className="uppercase text-lg text-center font-bold text-gray-500 tracking-tighter mt-2 border-b">
            Crop Image
          </h2>
          <div className="max-h-[80vh] overflow-y-auto">
            <div className="w-full md:w-3/5 mx-auto mt-2">
              <div className="w-full py-2 px-8 2xl:px-10 border">
                <ReactCrop
                  onImageLoaded={onImageLoaded}
                  crop={crop}
                  onChange={onCropChange}
                  onComplete={onCropComplete}
                  locked={true}
                  circularCrop={true}
                  className="w-full"
                >
                  <img
                    src={src}
                    className="w-full"
                    ref={imgRef}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                <div className="flex flex-col gap-1 w-full px-2 mt-3 md:w-2/3 mx-auto">
                  <input
                    defaultValue={radius}
                    type="range"
                    id="zoom"
                    name="zoom"
                    min="20"
                    max={maxZoom}
                    onChange={handleSliderChange}
                    className="w-full"
                  />
                  <div className="flex justify-center">
                    <button
                      onClick={handleCropImage}
                      className="py-1 px-2 my-2  btn-primary"
                    >
                      Crop Image
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ImageUploader;
