import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { timeAgo } from '../../../helper/dateTime';
import { MarkAsReadBtn } from '../../components/common/Button/MuiButtons';
import { markNotification } from '../../../services/RequestHandler/ApiRequest';

const FullPageNotifications = () => {
  const dispatch = useDispatch();
  const { notificationList } = useSelector(state => state.notification);
  const onMark = async slug => {
    await markNotification(dispatch, slug);
  };

  return (
    <div className=" bg-gray-100 flex flex-col items-center justify-center">
      <div className="text-center  pt-2 mt-8">NOTIFICATIONS</div>
      <div className="py-8 md:responsive-width w-full">
        <div className=" menu-items overflow-y-auto min-h-96	">
          {notificationList?.length ? (
            <div>
              {notificationList?.map(item => (
                <div className="w-full my-1" key={item?.id}>
                  <div
                    className={`my-2 py-2 md:ml-8 ml-1 md:mr-8 mr-1 bg-white border-gray-200 rounded-lg shadow  transition duration-300 hover:shadow-lg hover:border-orange-300 border-2 hover:bg-gray-50 ${item?.seen ? 'font-extralight' : 'font-bold'} `}
                  >
                    <div className="md:px-2 py-2 px-1 flex flex-col">
                      <div className="flex justify-between">
                        <div className="flex">
                          <span className="md:text-base text-sm">
                            Order: {item?.order_code}
                          </span>
                          <span className="ml-2">
                            {item?.seen ? null : (
                              <MarkAsReadBtn
                                onMark={() => onMark(item?.slug)}
                              />
                            )}
                          </span>
                        </div>
                        <div className="text-sm mr-2">
                          {timeAgo(item?.updated_at)}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-sm mt-2">{item?.details}</p>
                        </div>
                        <div>
                          {item?.review ? (
                            <p className="font-thin text-sm italic text-gray-500 mr-2 mt-2">
                              {/* Reviewed */}
                            </p>
                          ) : (
                            <Link
                              to={
                                !item?.review ? `/reviews/${item?.slug}` : '#'
                              }
                            >
                              <p className="inline-flex items-center px-3 py-2 md:text-sm text-xs font-medium text-center text-white bg-primary rounded-lg hover:bg-accentActive focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Review Now
                              </p>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full my-1 text-center"> No notifications!!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullPageNotifications;
