/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const filterBanners = banners =>
  banners.filter(banner => {
    const timeDiff = new Date(banner.disc_end_date) - new Date();
    return timeDiff > 0;
  });
const initialState = {
  pageState: 'idle',
  homepageBanner: {},
  deal_today: {},
  branches: [],
  featured_items: [],
  top_selling_items: [],
  discounted_items: [],
  banners: {
    banner_ones: [],
    banner_twos: [],
    banner_threes: [],
  },
};

const homePageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
    storeHomePageData: (state, action) => {
      const {
        homepageBanner,
        deal_today,
        branches,
        featured_items,
        top_selling_items,
        discounted_items,
      } = action?.payload || {};
      state.homepageBanner = homepageBanner || {};
      state.deal_today = deal_today || {};
      state.branches = branches || [];
      state.featured_items = featured_items || [];
      state.top_selling_items = top_selling_items || [];
      state.discounted_items = discounted_items || [];
    },
    storeBanners: (state, action) => {
      const { banner_ones, banner_twos, banner_threes } =
        action.payload || state.banners;
      state.banners.banner_ones =
        filterBanners(banner_ones) || state.banners.banner_ones;
      state.banners.banner_twos =
        filterBanners(banner_twos) || state.banners.banner_twos;
      state.banners.banner_threes =
        filterBanners(banner_threes) || state.banners.banner_threes;
    },
    removeBanner: (state, action) => {
      const { bannerId, bannerNumber } = action.payload;
      if (bannerId) {
        if (bannerNumber === 1) {
          state.banners.banner_ones = state.banners.banner_ones.filter(
            banner => banner.id !== bannerId,
          );
        } else if (bannerNumber === 2) {
          state.banners.banner_twos = state.banners.banner_twos.filter(
            banner => banner.id !== bannerId,
          );
        } else if (bannerNumber === 3) {
          state.banners.banner_threes = state.banners.banner_threes.filter(
            banner => banner.id !== bannerId,
          );
        }
      }
    },
  },
});

export const { storeHomePageData, storeBanners, removeBanner } =
  homePageSlice.actions;
export default homePageSlice.reducer;
