import ProductContent from './ProductContent';

const CategoryContent = ({
  id,
  label,
  categoryDetail,
  products,
  searchText,
}) => {
  let filteredProducts = products?.filter(product =>
    product?.name?.toLowerCase().includes(searchText?.toLowerCase()),
  );

  if (!filteredProducts || filteredProducts.length === 0) {
    filteredProducts = products;
  }

  return (
    <div id={id} className="mb-20">
      <div className="bg-gray-200 py-4 mb-1 px-4 rounded-sm flex flex-col shadow-md">
        <span className="font-bold">{label}</span>
        <span className="text-sm">{categoryDetail}</span>
      </div>
      {filteredProducts.map(product => (
        <div key={product?.slug}>
          <ProductContent
            name={product?.name}
            priceOld={product?.price_old}
            priceFinal={product?.price_final}
            product={product}
          />
          <hr
            className="mx-auto"
            style={{
              height: '2px',
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default CategoryContent;
