import { Helmet } from 'react-helmet-async';
import HomePageBanner from '../../components/HomePage/HomePageBanner';
import { useSelector } from 'react-redux';

import ItemListWithHeading from '../../components/common/ItemListWithHeading';
import RestroWithHeading from '../../components/common/RestroWithHeading';
import BannerThree from '../../components/HomePage/Banner/BannerThree';
import BannerTwo from '../../components/HomePage/Banner/BannerTwo';
import BannerOne from '../../components/HomePage/Banner/BannerOne';

export function HomePage() {
  const {
    homepageBanner,
    featured_items,
    top_selling_items,
    discounted_items,
    branches,
  } = useSelector(store => store.homePage);
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <HomePageBanner banner={homepageBanner} />

      <div className="product-items responsive-width">
        <ItemListWithHeading
          heading={'Featured Items'}
          itemsList={featured_items}
        />

        <BannerOne />

        <ItemListWithHeading
          heading={'Top Selling Items'}
          itemsList={top_selling_items}
        />

        <BannerTwo />

        <ItemListWithHeading
          heading={'Discounted Items'}
          itemsList={discounted_items}
        />

        <BannerThree />

        <RestroWithHeading heading="Our Restaurants" restrosList={branches} />
      </div>
    </>
  );
}
