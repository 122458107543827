import React, { useEffect } from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import VideoPlayer from '../../components/common/VideoPlayer';
import ServiceIconTitle from '../../components/About/ServiceIconTitle';
import AwardIcon from '../../../assets/svg/award-icon.svg';
import ControlDietIcon from '../../../assets/svg/control-diet-dieting-icon.svg';
import CustomerServiceIcon from '../../../assets/svg/customer-service-icon.svg';
import ShippedIcon from '../../../assets/svg/shipped-truck-icon.svg';
import { fetchAboutPageData } from '../../../services/RequestHandler/ApiRequest';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import TinyMceContent from '../../components/common/TinyMceContent';

const services = [
  {
    id: 1,
    title: 'Free shipping',
    subtitle: 'On order over Rs.1000',
    bgColor: 'bg-pink',
    icon: <ShippedIcon />,
  },
  {
    id: 2,
    title: 'Always Fresh',
    subtitle: 'Product well package',
    bgColor: 'bg-brown',
    icon: <ControlDietIcon />,
  },
  {
    id: 3,
    title: 'Superior quality',
    subtitle: 'Quality product',
    bgColor: 'bg-blue',
    icon: <AwardIcon />,
  },
  {
    id: 4,
    title: 'Support',
    subtitle: '24/4 support',
    bgColor: 'bg-lightGreen',
    icon: <CustomerServiceIcon />,
  },
];
function About() {
  const dispatch = useDispatch();
  const { aboutUs } = useSelector(state => state.aboutPage || {});
  useEffect(() => {
    dispatch(fetchAboutPageData());
  }, [dispatch]);
  return (
    <div className="font-roboto">
      <Helmet>
        <title>About</title>
        <meta name="description" content="About us page" />
      </Helmet>
      <HeroSection
        title="About Us"
        subtitle="About us"
        bgImage={aboutUs?.image_url}
      />
      <div className="video-section responsive-width gap-8 flex flex-col bg-gray-50 items-center px-20 py-16">
        <div className="flex-grow px-2">
          <p className="text-4xl font-bold mb-4">{aboutUs?.name}</p>
          <TinyMceContent
            className="text-sm tracking-wider text-gray-500 leading-6"
            content={aboutUs?.description}
          />
        </div>
        {aboutUs?.youtube && (
          <div className=" p-0 w-full">
            <VideoPlayer videoId={aboutUs?.youtube} />
          </div>
        )}
      </div>
      <section className="bg-[#F7F6F2] ">
        <div className="container responsive-width py-20 px-16 -mb-20">
          <div className="flex flex-col lg:flex-row justify-between items-center gap-6">
            {services.map(service => (
              <div key={service.id}>
                <ServiceIconTitle
                  title={service.title}
                  subtitle={service.subtitle}
                  bgColor={service.bgColor}
                  icon={service.icon}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
