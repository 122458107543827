import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postOauthLogin } from '../../../../services/RequestHandler/AuthRequest';
import OAuthLogin from '../OAuthLogin';
import { redirectBack } from '../../../../utils/redirectBack';
import { FacebookIcon, GoogleIcon } from '../../../../assets/Icon';

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const FACEBOOK_CLIENT_ID = import.meta.env.VITE_FACEBOOK_CLIENT_ID;
const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL;

export const GoogleLogin = ({ setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const oauthLogin = async payload => {
    const response = await postOauthLogin(dispatch, payload);
    setLoading(false);
    if (response) {
      redirectBack(navigate);
    }
  };

  return (
    <LoginSocialGoogle
      client_id={`${GOOGLE_CLIENT_ID}`}
      redirect_uri={`${FRONTEND_URL}/login`}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      onResolve={({ data }) => {
        const payload = {
          provider: 'google',
          email: data?.email,
          first_name: data?.given_name,
          last_name: data?.family_name,
          id: data?.sub,
          access_token: data?.access_token,
        };
        if (payload?.email?.length && payload?.id?.length) {
          oauthLogin(payload);
        }
      }}
      onReject={() => {
        toast.error('Cannot login. Please try again ! ');
        setLoading(false);
      }}
      onLoginStart={() => {
        setLoading(true);
      }}
    >
      <OAuthLogin icon={<GoogleIcon width="30" />}>
        <p>Login with Google</p>
      </OAuthLogin>
    </LoginSocialGoogle>
  );
};

export const FacebookLogin = ({ setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const oauthLogin = async payload => {
    setLoading(true);
    const response = await postOauthLogin(dispatch, payload);
    setLoading(false);
    if (response) {
      redirectBack(navigate);
    }
  };
  return (
    <LoginSocialFacebook
      appId={`${FACEBOOK_CLIENT_ID}`}
      fieldsProfile={'id,first_name,last_name,name,name_format,picture'}
      redirect_uri={`${FRONTEND_URL}/login`}
      onResolve={({ data }) => {
        const payload = {
          provider: 'facebook',
          email: data?.email || '',
          first_name: data?.first_name,
          last_name: data?.last_name,
          id: data?.id,
          picture: data?.picture?.data?.url?.replace(/\\+/g, ''),
          access_token: data?.accessToken,
        };
        if (payload?.id?.length) {
          oauthLogin(payload);
        }
      }}
      onReject={() => {
        toast.error('Cannot login. Please try again ! ');
        setLoading(false);
      }}
      onLoginStart={() => setLoading(true)}
    >
      <OAuthLogin icon={<FacebookIcon width="30" />}>
        <p>Login with Facebook</p>
      </OAuthLogin>
    </LoginSocialFacebook>
  );
};
