import React, { useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import MarkerIcon from '../../../assets/svg/google-marker.svg';

function MapContainer({ setAddress, height, width }) {
  const containerStyle = {
    width: width || '100%',
    height: height || '400px',
  };

  const { isGoogleMapApiLoaded } = useSelector(state => state.googleMap);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);

  useEffect(() => {
    // Get the user's current position
    navigator.geolocation.getCurrentPosition(
      position => {
        const currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(currentPosition);
      },
      error => {
        console.error('Error getting location:', error);
        // If there's an error, you can set a default center or handle it differently
        setCenter({ lat: 27.7172, lng: 85.324 });
      },
    );
  }, []);

  const onMapLoad = map => {
    setMap(map);
  };

  const onDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter().toJSON();
      setCenter(newCenter);
      // Use Geocoding service to get the address
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newCenter }, (results, status) => {
        if (status === 'OK' && results[0]) {
          setAddress(center, results[0].formatted_address);
        } else {
          console.error(
            'Geocode was not successful for the following reason:',
            status,
          );
        }
      });
    }
  };

  return (
    <div>
      {isGoogleMapApiLoaded && center ? (
        <div className="relative w-full">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
            onLoad={onMapLoad}
            onDragEnd={onDragEnd}
          ></GoogleMap>
          <div className="absolute scale-175 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-full">
            <MarkerIcon />
          </div>
        </div>
      ) : (
        <div>Loading Google Maps...</div>
      )}
    </div>
  );
}

export default MapContainer;
