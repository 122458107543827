/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pageState: 'idle',
  blogs: [],
  recentBlogs: [],
};

const blogPageSlice = createSlice({
  name: 'blogpage',
  initialState,
  reducers: {
    storeBlogPageData: (state, action) => {
      const { blogs, recent_blogs } = action.payload;
      state.blogs = blogs?.length ? blogs : state.blogs;
      state.recentBlogs = recent_blogs || state.recentBlogs;
      state.pageState = 'success';
    },
    setBlogPagePending: state => {
      state.pageState = 'pending';
    },
  },
});
export const getBlogBySlug = (state, slug) => {
  const { blogs, recentBlogs } = state.blogPage;
  const allBlogs = [...blogs, ...recentBlogs];
  return [
    allBlogs.find(blog => blog.slug === slug),
    state.blogPage.recentBlogs,
  ];
};

export const { storeBlogPageData, setBlogPagePending } = blogPageSlice.actions;
export default blogPageSlice.reducer;
