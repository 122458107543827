import { Navigate } from 'react-router-dom';

const Protected = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    sessionStorage.setItem('redirectPath', window.location.pathname);
    return <Navigate to="/login" />;
  }
  return children;
};
export default Protected;
