import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBlogBySlug } from '../../../store/slice/blogpage';
import Spinner from '../../components/common/Spinner';
import { fetchBlogBySlug } from '../../../services/RequestHandler/ApiRequest';
import HeroSection from '../../components/HeroSection/HeroSection';
import RecentBlogs from '../../components/Blog/RecentBlogs';
import BlogDetail from '../../components/Blog/BlogDetail';
import { Helmet } from 'react-helmet-async';

function BlogDetailPage() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [storeBlog, storeRecentBlogs] = useSelector(state =>
    getBlogBySlug(state, slug),
  );
  const [blog, setBlog] = useState(storeBlog);
  const [recentBlogs, setRecentBlogs] = useState(storeRecentBlogs);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchBlogBySlug(slug);
      setBlog(res?.blog);
      setRecentBlogs(res?.recent_blogs);
      setIsLoading(false);
    };
    if (!storeBlog || !storeRecentBlogs?.length) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) return <Spinner />;
  return (
    <div className="font-roboto">
      <Helmet>
        <title>Blog</title>
        <meta name="description" content="Blog detail page" />
      </Helmet>
      <HeroSection title="Blogs" bgImage="" />
      <div className="responsive-width my-20">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="col-span-full lg:col-span-2">
            {Object.keys(blog).length ? (
              <BlogDetail blog={blog} />
            ) : (
              <h1>Blog do not exist!</h1>
            )}
          </div>
          <div className="recent-blogs col-span-full lg:col-span-1 pt-4">
            <h1 className="text-xl">Recents Blogs</h1>
            {recentBlogs?.length ? (
              <RecentBlogs blogs={recentBlogs} />
            ) : (
              <h1>No Blogs!!</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailPage;
