import { useFormContext } from 'react-hook-form';
import MapContainer from './MapContainer';
import { useEffect } from 'react';

function DeliveryDetailOfGift() {
  const {
    register,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext({ defaultValues: {} });
  const setAddress = address => setValue('deliveryAddress', address);
  useEffect(() => {
    reset();
  }, []);
  return (
    <div>
      <h1 className="text-2xl mb-6 text-center text-green-600 tracking-widest	font-black">
        GIFT YOUR FRIEND 🎁
      </h1>
      <div className="border p-4">
        <div className="flex flex-col gap-6 mb-6">
          <h1 className="text-2xl">Enter Your Friend&apos;s Details</h1>
          <div className="form-control">
            <div className="flex flex-col gap-2">
              <label htmlFor="friend_name">Name</label>
              <input
                className="py-2.5 w-full px-4 border"
                type="text"
                placeholder="Your friend's full name"
                {...register('friend_name', {
                  required: 'This field is required',
                })}
              />
            </div>
            {errors.friend_name && (
              <div className="mt-1">
                <p className="text-xs text-red-600">
                  {errors.friend_name.message}
                </p>
              </div>
            )}
          </div>
          <div className="form-control">
            <div className="flex flex-col gap-2">
              <label htmlFor="friend_number">Phone Number</label>
              <input
                className="py-2.5 w-full px-4 border"
                type="text"
                placeholder="Your friend's phone number"
                {...register('friend_number', {
                  required: 'This field is required',
                  pattern: {
                    value: /^(98|97|01)\d{8}$/,
                    message: 'Invalid phone number',
                  },
                })}
              />
            </div>
            {errors.friend_number && (
              <div className="mt-1">
                <p className="text-xs text-red-600">
                  {errors.friend_number.message}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="border p-4 my-4">
        <div className="flex flex-col gap-6 mb-6">
          <h1 className="text-2xl mb-2">Delivery Location </h1>
          <div className="form-control">
            <div className="flex flex-col gap-2">
              <label htmlFor="friend_address">Address</label>
              <input
                className="py-2.5 w-full px-4 border"
                type="text"
                placeholder="Your friend's address"
                {...register('friend_address', {
                  required: 'This field is required',
                })}
              />
            </div>
            {errors.friend_address && (
              <div className="mt-1">
                <p className="text-xs text-red-600">
                  {errors.friend_address.message}
                </p>
              </div>
            )}
          </div>
          <div className="form-control">
            <div className="flex flex-col gap-2">
              <label htmlFor="friend_landmark">Nearby Landmark</label>
              <input
                className="py-2.5 w-full px-4 border"
                type="text"
                placeholder="Your friend's nearest landmark"
                {...register('friend_landmark', {
                  required: 'This field is required',
                })}
              />
            </div>
            {errors.friend_landmark && (
              <div className="mt-1">
                <p className="text-xs text-red-600">
                  {errors.friend_landmark.message}
                </p>
              </div>
            )}
          </div>
          <input
            type="hidden"
            {...register('deliveryAddress', {
              required: 'Please select delivery address',
            })}
          />
          <div className="w-full">
            <MapContainer setAddress={setAddress} />
          </div>
          {errors.deliveryAddress && (
            <p className="text-xs text-red-600 mt-2">
              {errors.deliveryAddress.message}
            </p>
          )}
        </div>
      </div>
      <div className="border p-4 my-4">
        <div className="py-2">
          <h1 className="text-2xl">
            Delivery Option <span className="text-red-600">*</span>
          </h1>
          <div>
            <div className="flex items-center mb-3 mt-4">
              <input
                defaultChecked
                type="radio"
                value="delivery"
                {...register('deliveryOption', {
                  required: 'Please select one delivery option',
                })}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
              />
              <label className="ml-2 font-medium text-gray-900">
                Delivery{' '}
                <span className="text-xs opacity-70">
                  (Delivery to your address)
                </span>
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                value="takeaway"
                {...register('deliveryOption', {
                  required: 'Please select one delivery option',
                })}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
              />
              <label className="ml-2 font-medium text-gray-900">
                Takeaway{' '}
                <span className="text-xs opacity-70">
                  (Visit our restaurant){' '}
                </span>
              </label>
            </div>
          </div>
          <p className="text-red-600 text-xs"></p>
        </div>
        <hr className="my-2" />
        <div className="py-2">
          <h1 className="text-2xl">
            Payment Method <span className="text-red-600">*</span>
          </h1>

          <div className="flex items-center mb-3 mt-4">
            <input
              defaultChecked
              type="radio"
              value="cash"
              {...register('payment_method', {
                required: 'Please select one delivery option',
              })}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label
              htmlFor="default-radio-1"
              className="ml-2 font-medium text-gray-900"
            >
              Cash
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              value="esewa"
              {...register('payment_method', {
                required: 'Please select one delivery option',
              })}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            />
            <label
              htmlFor="default-radio-2"
              className="ml-2 font-medium text-gray-900"
            >
              Esewa
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryDetailOfGift;
