import React from 'react';
import { useSelector } from 'react-redux';
import Banners from '.';

function BannerTwo() {
  const { banner_twos: banners } = useSelector(state => state.homePage.banners);
  if (banners?.length <= 0) return null;
  return <Banners banners={banners} bannerNumber={2} />;
}

export default BannerTwo;
