/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isGoogleMapApiLoaded: false,
};

const googleMapApiSlice = createSlice({
  name: 'googleMap',
  initialState,
  reducers: {
    setGoogleMapApiLoaded: state => {
      state.isGoogleMapApiLoaded = true;
    },
    setGoogleMapApiNotLoaded: state => {
      state.isGoogleMapApiLoaded = false;
    },
  },
});

export const { setGoogleMapApiLoaded, setGoogleMapApiNotLoaded } =
  googleMapApiSlice.actions;
export default googleMapApiSlice.reducer;
