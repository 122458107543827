import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';

function CustomCarousel({ children, ...props }) {
  return (
    <div {...props}>
      <Carousel
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        showThumbs={false}
      >
        {children}
      </Carousel>
    </div>
  );
}

export default CustomCarousel;
