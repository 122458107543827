import { toast } from 'react-toastify';
import Api from '../Api';
import { setAccessTokens } from '../../utils/tokens';
import { login, expired } from '../../store/slice/login';
import { fetchData } from './ApiRequest';
import { refreshCart } from '../../store/slice/cart';
import { refreshFavourites } from '../../store/slice/favoriteItems';
import { refreshNotifications } from '../../store/slice/notification';

export const postData = async (url, body) => {
  try {
    const res = await Api.post(url, body);
    return res?.data;
  } catch (error) {
    console.error('Error occured while posting data', error);
  }
};

export const postRegistration = async (dispatch, payload) => {
  try {
    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      password_confirmation,
    } = payload;
    const body = {
      firstName,
      lastName,
      email,
      password,
      phone,
      password_confirmation,
    };

    const response = await postData('/auth/register', body);
    if (response?.status === 201) {
      setAccessTokens(response?.data?.access_token);
      dispatch(login(response?.data));
      toast.success(response?.message);
      return true;
    } else {
      const parsedMessage = response;
      if (parsedMessage.success === false && parsedMessage.message) {
        toast.error(
          parsedMessage.message || 'Some error occurred during signup',
        );
      }
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};

export const postLogin = async (dispatch, payload) => {
  try {
    const { email, password, remember } = payload;
    const body = { email, password, remember };
    const response = await postData('/auth/login', body);
    if (response?.data?.access_token && response?.status === 200) {
      setAccessTokens(response?.data?.access_token);
      toast.success(response?.message);
      dispatch(login(response?.data));
      dispatch(
        refreshFavourites({ favoriteItems: response?.data?.favourites }),
      );
      const refreshCarts = [];
      response?.data?.carts?.carts?.forEach(({ product, unit }) => {
        refreshCarts.push({ ...product, quantity: unit, unit });
      });
      dispatch(
        refreshCart({
          refreshCarts,
          totalItems: response?.data?.carts?.totalUnits,
        }),
      );
      return true;
    } else {
      toast.error(response?.message);
    }

    return;
  } catch (error) {
    toast.error(error.message);
  }
};

export const postOauthLogin = async (dispatch, payload) => {
  try {
    const { email, first_name, last_name, picture, id, provider, access_token } = payload;
    const body = {
      provider,
      email,
      first_name,
      last_name,
      picture,
      id,
      access_token,
    };
    const response = await postData('/auth/oauth/provider', body);
    if (response?.data?.access_token && response?.status === 201) {
      setAccessTokens(response?.data?.access_token);
      dispatch(login(response.data));
      toast.success(response?.message);
      const refreshCarts = [];
      response?.data?.carts?.carts?.forEach(({ product, unit }) => {
        refreshCarts.push({ ...product, quantity: unit, unit });
      });
      dispatch(
        refreshCart({
          refreshCarts,
          totalItems: response?.data?.carts?.totalUnits,
        }),
      );
      dispatch(
        refreshFavourites({ favoriteItems: response?.data?.favourites }),
      );
      dispatch(
        refreshNotifications({ notifications: response?.data?.notifications }),
      );
      return response;
    }
    toast.error(response?.message);
    return null;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getMe = async dispatch => {
  try {
    const response = await fetchData('/me');
    if (response?.data?.user) {
      dispatch(login(response?.data));
      dispatch(
        refreshFavourites({ favoriteItems: response?.data?.favourites }),
      );
      const refreshCarts = [];
      response?.data?.carts?.carts?.forEach(({ product, unit }) => {
        refreshCarts.push({ ...product, quantity: unit, unit });
      });
      dispatch(
        refreshCart({
          refreshCarts,
          totalItems: response?.data?.carts?.totalUnits,
        }),
      );
      dispatch(
        refreshNotifications({ notifications: response?.data?.notifications }),
      );
      return true;
    } else {
      if (response) {
        dispatch(expired());
        toast.error(response?.message);
      }
    }

    return false;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
};

export const postSendCode = async payload => {
  try {
    const { email } = payload;
    const body = { email };
    const response = await postData('/auth/forgot-password/send-code', body);
    if (response?.status === 201) {
      toast.success(response?.message);
      return true;
    } else {
      toast.error(response?.message);
    }
    return false;
  } catch (error) {
    toast.error(error.message);
  }
};

export const postVerifyCode = async payload => {
  try {
    const { email, code } = payload;
    const body = { email, code, type: 'forgot-password' };
    const response = await postData('/auth/forgot-password/verify-code', body);
    if (response?.status === 304) {
      toast.success(response?.message);
      return true;
    } else {
      toast.error(response?.message);
    }
    return false;
  } catch (error) {
    toast.error(error.message);
  }
};

export const postResetPassword = async payload => {
  try {
    const { email, password, password_confirmation } = payload;
    const body = { email, password, password_confirmation };
    const response = await postData(
      '/auth/forgot-password/reset-password',
      body,
    );
    if (response?.status === 201) {
      toast.success(response?.message);
      return true;
    } else {
      toast.error(response?.message);
    }
    return false;
  } catch (error) {
    toast.error(error.message);
  }
};
