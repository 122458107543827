import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../store/slice/login';
import { clearFavorites } from '../../../../store/slice/favoriteItems';
import { persistor } from '../../../../store/index';

function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const logoutHandle = async () => {
      dispatch(logout());
      dispatch(clearFavorites());
      return persistor.purge();
    };
    logoutHandle().finally(navigate('/'));
  }, [dispatch]);

  return null;
}

export default LogoutPage;
