import React from 'react';

function BannerImage({ image }) {
  return (
    <div>
      <img src={image} alt="banner" />
    </div>
  );
}

export default BannerImage;
