import React from 'react';
import RestrosCardGroup from '../../common/RestroCardGroup';

const OtherBranches = ({ brancheList = [] }) => {
  return (
    <div className="container mx-auto">
      {!brancheList.length ? (
        <div className="py-3 text-center">No Other Branches</div>
      ) : (
        <RestrosCardGroup restros={brancheList} />
      )}
    </div>
  );
};

export default OtherBranches;
